  import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Input, ChangeDetectorRef } from "@angular/core";
  import { DataService } from "../../data.service";
  import { AuthenticationService } from "../../authentication.service";
  import { environment } from "../../../environments/environment";
  import { Article } from "../../model/article";
  import { Vente } from "../../model/vente";
  import { DecimalPipe, UpperCasePipe } from "@angular/common";
  import * as XLSX from "xlsx";
  import { Client } from "../../model/client";
  import { Achat } from "../../model/achat";
  import {Message,MessageService,ConfirmationService} from 'primeng/api';
  import { Charge } from '../../model/charge';
  import { Paiement } from '../../model/paiement';
  import { Depot } from "../../model/depot";
  import { ModePaiement } from "src/app/model/modePaiement";
  import { HostListener } from '@angular/core';
  import { AutoComplete } from 'primeng/autocomplete';// declare var $:JQueryStatic;
  import { User } from "src/app/model/user";
  import { Avoir } from "src/app/model/avoir";
  import { DataBase } from "src/app/model/dataBase";
import { Type } from "src/app/model/type";
import { Tactile } from "src/app/model/tactile";
  // declare var $;
  // import {CheckboxModule} from 'primeng/checkbox';
  @Component({
    selector: "app-tactile",
    templateUrl: "tactile.component.html",
    styleUrls: ["tactile.component.scss"],
    providers: [DecimalPipe,MessageService,ConfirmationService],
  })
  export class TactileComponent implements OnInit {
    // @Input() francais: boolean ;
    // @Input() langueDirection: string ;
    // @Input() langueLbl: string ;  
    
    @ViewChild('autoCompleteObject') private autoCompleteObject: AutoComplete ;
    key:string;
    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {     
      var activeElement = document.activeElement;   
      var inputs = ['input', 'select', 'button', 'textarea'];
      if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        
      }else{
        this.key += event.key;
        if(this.key!=undefined){
          if(this.key.includes("Enter")){
            this.key = this.key.replace("Enter", "");
            this.key = this.key.replace("undefined", "");
            this.key = this.key.replace(/\s+/g, '');
            if(this.key){
              this.addArticleBarCode(this.key,'codeBarre');
            }            
            this.key="";
          } 
        } 
      }
    }
    msgs1: Message[];    
    msgs2: Message[];
  
    idArticle: number;
    nbre:number;
    higthCol:string;
    idArticleTest: number;
    venteX: number;
    Article: Article = {};
    Articles: Article[];
    Tactile: Tactile = {};
    Tactiles: Tactile[];
    ArticlesFavoris: Article[];
    ArticlesTop10: Article[];
    ArticlesRecherche: Article[];
    tableDetailVentess: Vente[]; 
    Donnees     : DataBase [];
    Donnee      : DataBase = {};
    historyPrice: string = "" ;  
    Familles: any = [];
    stock: Article[];
    Achat: Achat = {};
    sidebarVisible:boolean=false;
    sidebarVisibleInstance:boolean=false;
    showPrixAchat:boolean=false;
    showTop10:boolean=false;
    iconSearch :string= "pi pi-search";
    idArt :number=0;
    // priceHide:boolean=true;
    // pricePosition:string='Center';
    // priceDhs:string='Dhs';
    // priceFontSize:number=12;
    // designationHide:boolean=true;
    // designationEllipsis:boolean=true;
    // designationPosition:string='Center';
    // designationFontSize:number=12;
    // showHideClass:string ="pi pi-eye";
    // bonWidght:number=35;
    // pricePositionH:number=35;
    // pricePositionV:number=35;
    // photoWidth:number=150;
    // photoHeight:number=100;
    // bonFavorisHight:number=35;
    alignButton: any;

    justifyOptions: any[] = [];

    // Achats: Achat[];
    rechercheArticle: Article = {};
    rechercheArticles: Article[];
    Vente: Vente = {};
    Ventes: Vente[];
    selectedVente: Vente;
    selectedArticle:string;
    tableVentes: Vente[];
    //----------Paiement Recap----------//
      tableVentess: Vente[];
      tableRecapPaiements: Paiement [];
      tableRecapSoldeInitial: Paiement [];
      tableRecapAvoirs: Avoir[];
      tableRecapAvoirAchats: Avoir[];
      tableRecapAchats: Achat[];
      Avoir: Avoir = {};
      reste?:any;
    //----------Paiement Recap----------//
  
    tableDetailVentes: Vente[];
    artilcesClient: Vente[];
    artilceClient: Vente={};
    Users                : User[];
    User                 : User={};
    Revendeurs           : User[];
    Revendeur            : User={};
    selectedCommandeDetail: Vente;
    tableCommandeVente: Vente = {};
    tableCommandeVentes: any = [];
    requettesCommandeVentes: any = [];
    tableAvance: any = {};
    tableAvances: any = [];
    tableClient: any = {};
    tableArticlestock: any = [];
    requetteCommandeVente: Vente = {};
    requetteCommandeVentes: any = [];  
    instanceBtn: any = [];  
    idInstance: number=0;  
    requetteCommandeVentesInstancee: any = [];  
    requetteCommandeVentesInstance: any = [];  
    requetteCommandeVentesInstance1: any = [];  
    requetteCommandeVentesInstance2: any = [];  
    requetteCommandeVentesInstance3: any = [];  
    requetteCommandeVentesInstance4: any = [];  
    requetteCommandeVentesInstance5: any = [];
    ClientsBon: Vente[];
    Client: Client = {};
    Clients: Client[]; 
    dataTable   : string;
    displayDialog: boolean;
    displayPaiement: boolean;
    desablePrint: boolean ;
    showButon: boolean;
    showButonSauvgarder: boolean;
    bon: boolean= false;
    showButonAvance: boolean = false;
    showButonClient: boolean = false;
    comptoir: boolean = false;
    blockedDocument: boolean= false;
    blockedDocumentt: boolean;
    blockedcalculator: boolean= false;
    displayDialogPaiement: boolean= false;
    designation: string='';
    icon: string='images';
    changeImage: boolean= true;
    quantite: number=0;
    designationSearch: string='';
    nomComplet:string='';
    revendeur:string='';
    nbon:string='';
    numm:string='';
    nomAr:string='';
    typeVente:string ='comptoir';
    nom:string;
    nomDepot:string;
    totalMontant: any;
    prixVente: number;
    totalMarge: number;
    totalQte: number;
    total: number;
    remise: number;
    margeTotal: number;
    idClient: number;
    quantiteVenteGros: number;
    quantiteVenteDetail: number;
    prixVenteGros: number;
    prixVenteDetail: number;
    prixVente1: number;
    prixAchat: number;
    idDepotVente: number;
    Exclut:string;  
    index: string;
    colorInput: string;
    indexAvance: string;
    commandeDetail: string;
    commandePaiement: string;
    commandeDetailDepot: string;
    commandeDetailCamion: string;
    prixArticle: string;
    dateDebut: any;
    dateFin: any;
    dateSituationDebut: string;
    dateSituationFin: string;
    msgs: Message[] = [];
    position: string;
    displaybarcode: boolean;
    displayCharge: boolean;
    displayModePaiement: boolean;
    displayDialogClient: boolean;
    RemiseCheked: boolean=false;
    afficher: boolean;
    BarCode:string;
    tableDepot: Depot = {};
    Depot: Depot = {};
    Depots: Depot[];
    Type: Type = {};
    Types: Type[];
    Stocks: Article[];
    rechercheArticlee: Article[];
    idType: any;
    config: any = {};
    type: string;
    typee: string;
    images: string;
    ModePaiement: ModePaiement = {};
    ModePaiements: ModePaiement[];
    depots: string = "Depots" ;
    able: boolean =false;
    AdminAble: boolean =false;
    depotActivate: boolean =false;
    dropdownArticle: boolean =false;
    afficherCodebarre: boolean =false;
    myUrl: string = `${environment.urlApi}`;
    francais: boolean =false;
    langueDirection: string ="ltr";
    langueLbl: string ="العربية";
    edited:boolean=false;
    Charge: any = {};
    Charges: any[];
    totalCharge:number;
    totalAvance:number;
    nbreBon:number;
    colspan:number;
    Paiement: any = {};
    Paiements: any[];
    totalPaiement:number;
    tableDetailPaiements: Paiement[];
    filteredArticles: any[];
    filteredCodeBare: any[];
    filteredReference: any[];
    currentNumber = '0';
    firstOperand = null;
    checkbox:  boolean = true;
    operator = null;
    waitForSecondNumber = false;
    public innerWidth: any;
    public innerHeightt: number;
    public innerHeight: any;
    public innerHeightCard: any;  
    styleOBJ :{};
    i:number=0;
    public getNumber(v: string){
      // console.log(v);
      // console.log(this.i);
      if(this.waitForSecondNumber){
          this.currentNumber = v;
          this.waitForSecondNumber = false; 
        }else{
          if(this.i==0){
            this.currentNumber = v;
          }else{
            this.currentNumber === '0'? this.currentNumber = v: this.currentNumber += v;  
          }
          this.i++;
        }
      //  this.total = this.currentNumber * this.vente.prixVente;
      }
    
      public getDecimal(){
        this.currentNumber += '.';
      }
      public clear(){
        this.currentNumber = '0';
        this.i=0;
        this.firstOperand = null;
        this.operator = null;
        this.waitForSecondNumber = false;
        this.selectedArticle="";
        // this.blockedDocumentt= false;
        // this.blockedcalculator= false;    
      }  
      public del(){
          this.currentNumber = this.currentNumber.toString().slice(0, -1)
      }
      public ok(){
        // console.log(this.currentNumber);
        if(this.currentNumber!='0' && this.currentNumber!=''){
          let nbre = parseFloat(this.currentNumber);
          this.i=0;
          // console.log(this.tableCommandeVentes);
          // console.log(this.idArticle);

          var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == this.idArticle);
          // console.log(this.tableCommandeVentes);
          if(this.activatePrice){
            // this.prixVen=nbre;
            if(this.tableCommandeVentes[index].prixVenteGros>nbre){
              this.messageService.add({severity:'warn', summary: 'إنتبـــــاه !!!', detail: "ثمن البيع أقل من ثمن الشراء "+ this.tableCommandeVentes[index].prixAchat });
            }else{
              this.tableCommandeVentes[index].prixV=nbre;
              this.tableCommandeVentes[index].prixVente=nbre;
              this.blockedDocumentt= false;
            }
          }else{
            this.quantite=nbre;
            this.tableCommandeVentes[index].quantite=nbre;
            this.blockedDocumentt= false;
          }
                
          // console.log(this.tableCommandeVentes[index]);         
          this.tableCommandeVentes[index].total = this.tableCommandeVentes[index].prixVente*this.tableCommandeVentes[index].quantite;
          this.totalQantite();
          this.prepareCommandeDetail();
          this.tableCommandeVente = {};
          // this.blockedDocumentt= false;
          this.blockedcalculator= false;
          this.currentNumber='0';     
        }else{
          this.selectedArticle="";
          // this.blockedDocumentt= false;
          this.blockedcalculator= false;
          this.currentNumber='0'; 
        }
    
      }
    tableData = [];
    @ViewChild('dataTable', {static: true}) table;
    title = 'Example of Angular 8 DataTable';
    dtOptions: DataTables.Settings = {};
  
    constructor(
      private data: DataService,
      private _decimalPipe: DecimalPipe,
      public AuthenticationService: AuthenticationService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private cdr: ChangeDetectorRef
    ) {}
    addSingle() {
      this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
  }
  
    ngOnInit() {
      this.styleOBJ = {'background':'coral','margin': '10px', 'padding': '10px', 'width': '99%'};
      this.blockedDocumentt = false;
      this.justifyOptions = [
        { icon: 'pi pi-align-left', justify: 'Left' },
        { icon: 'pi pi-align-center', justify: 'Center' },
        { icon: 'pi pi-align-right', justify: 'Right' },
    ];
      if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
        this.able=true;
      }
      if(this.AuthenticationService.currentUserValue.groupe=='Admin' ){
        this.AdminAble=true;
      }
      if(this.AuthenticationService.currentUserValue.depot== true){
        this.depotActivate=true;
      }
      if(this.AuthenticationService.currentUserValue.francais){
        this.francais=false;
        this.langueDirection="ltr"
        this.langueLbl="العربية"
      }else{
        this.francais=true;
        this.langueDirection="rtl"
        this.langueLbl="الفرنسية"
      }
      
      this.innerWidth = window.innerWidth;
      this.innerHeightt = window.screen.height-60;
      this.innerHeight = (window.screen.height-50) +"px";
      this.innerHeightCard = (window.innerHeight-95) +"px";
      // alert(window.screen.height);
      this.Vente.cloture==false;
      this.dropdownArticle=true;
      this.commandeDetail="";
      this.msgs1 = [
        {severity:'success', summary:'Success', detail:'Message Content'},
        {severity:'info', summary:'Info', detail:'Message Content'},
        {severity:'warn', summary:'Warning', detail:'Message Content'},
        {severity:'error', summary:'Error', detail:'Message Content'}
       ];
      this.dateSituationDebut = new Date().toISOString().split("T")[0];
      this.dateSituationFin = new Date().toISOString().split("T")[0];
      this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
      this.article();
      this.tactile(); 
      // this.getArticles();
      // this.getTypes();
      this.revendeurs();
      this.getDataTable();
      this.client();
      // this.clientNbreBon();
      this.depot();
      this.modePaiement();
      this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
      this.paiementDetail();
      this.ventes();
  
      this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
      this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
      this.Vente.nom="";
      this.depots="Ventes";
      if(!this.able && !this.depotActivate){this.colspan=4;
      }else if(!this.able && this.depotActivate){this.colspan=3;
      }else if(this.able && !this.depotActivate){this.colspan=5;
      }else if(this.able && this.depotActivate){this.colspan=4;}
      this.donnee();
      this.updatePageDimensions();
      if (window.innerHeight != screen.height) {
        document.documentElement.requestFullscreen();
      }
    }

    @HostListener('window:resize')
    onResize() {
      this.updatePageDimensions();
    }
    pageHeight: number;
    pageWidth: number;
    pageHeightt: any;
    pageWidtht: any;
    pageHeightMenuLeft: any;
    pageHeightMenuLeftScoll: any;
    pageHeightMenu: any;
    pageHeightTable: any;
    pageHeightArticle: any;

    creatInstance(){
    this.instanceBtn = [];
    // this.requetteCommandeVentesInstance = [];
    // console.log(this.requetteCommandeVentes);
      for (let i = 1; i < 7; i++) {
        // console.log(i);
        // console.log(this.requetteCommandeVentesInstance[i]);
        if(this.requetteCommandeVentesInstance[i]==undefined){
          this.requetteCommandeVentesInstance[i]=JSON.parse(JSON.stringify(this.requetteCommandeVentes));
          this.requetteCommandeVentesInstance[0]=this.requetteCommandeVentes;
          // console.log(this.requetteCommandeVentesInstance[i])
          this.instanceBtn.push(i);
          // console.log( this.instanceBtn); 
          // this.requetteCommandeVentesInstance[i]=JSON.parse(JSON.stringify(this.requetteCommandeVentes));
          this.initialiserBon();
          // console.log(this.requetteCommandeVentesInstance[i]);
          var affetcter=true;
          break;
        }
      }
      if(!affetcter){
        this.messageService.add({severity:'warn', summary: 'Instance', detail: 'Instance doit être < 7'});
      }
    }
    
    findInstance(){
      this.sidebarVisibleInstance = true
      this.instanceBtn=[];
      for (let i = 1; i < 7; i++) {
        // console.log(this.requetteCommandeVentesInstance[i]);        
        if(this.requetteCommandeVentesInstance[i]!=undefined){
          this.instanceBtn.push(i);        
        }        
      }
    }
    
    showInstance(idInstance,element){
      for (let i = 1; i < 7; i++) {
        element.hide(true);
      }
      element.show(true);
      this.idInstance=idInstance;
      this.requetteCommandeVentesInstancee=JSON.parse(JSON.stringify(this.requetteCommandeVentesInstance[idInstance]));
    }
    affectInstance(requetteCommandeVentesInstance,idInstance,element){
      this.initialiserBon();
      this.requetteCommandeVentes=requetteCommandeVentesInstance;
      this.tableCommandeVentes=requetteCommandeVentesInstance;
      this.requetteCommandeVentesInstance[idInstance]=undefined;
      element.hide(true);
      this.findInstance();
    }
    deleteInstance(requetteCommandeVentesInstance,idInstance,element){
      this.initialiserBon();
      this.requetteCommandeVentesInstance[idInstance]=undefined;
      element.hide(true);
      this.findInstance();
    }
    updatePageDimensions() {
      var height=120;
      this.pageHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      this.pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.pageWidtht=(this.pageWidth*0.35)+'px';
      this.pageHeightt=(this.pageHeight-58)+'px';
      this.pageHeightMenuLeft=(this.pageHeight-height-90)+'px';
      this.pageHeightMenuLeftScoll=(this.pageHeight-height-185)+'px';
      this.pageHeightMenu=(this.pageHeight-height)+'px';
      this.pageHeightTable=(this.pageHeight-height-30)+'px';
      this.pageHeightArticle=(this.pageHeight-height-120)+'px';

           // Output the values to the console
      // console.log('Page Height:', this.pageHeight);
      // console.log('Page Width:', this.pageWidth);

    }
    barcodeValue : any="24566";
    updateBarcode() {
      // this.barcodeValue = '67890';
      // this.cdr.detectChanges();
    }

  // Regenerate
    // getTypes() {
    //   this.Type.action = 'Select';
    //   this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
    //   this.data.type(this.Type).toPromise().then((x: Type[]) => (this.Types = x));
    // }
    selectType(data) {      
      this.showTop10=false;
      this.images = data.images;
      this.type = data.type;
      this.idType = data.id;
      this.rechercheArticlee = this.Articles.filter((x) => x.idType == data.id);
      this.config = {
        itemsPerPage: 16,
        currentPage: 1,
        totalItems: this.rechercheArticlee.length
      };
      // console.log(this.rechercheArticle);
    }
    selectTop10(){
      this.showTop10=true;
      this.idType =0;
    }
    selectFavoris(data) {
      // console.log(data);
      this.showTop10=false;
      this.images = data.images;
      this.type = data.designation;
      this.idType = data.id;
      // console.log(this.Articles);
      this.rechercheArticlee = this.Articles.filter((x) => x.idFavoris == data.id);
      // console.log(this.rechercheArticlee);
      this.config = {
        itemsPerPage: 16,
        currentPage: 1,
        totalItems: this.rechercheArticlee.length
      };
      // console.log(this.rechercheArticle);
    }
    donnee() {
      this.Donnee.action = "Select";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((x: DataBase[]) => (this.Donnees = x));
    }
    rechercher(){
      // this.blockedDocument=true;
      // this.paiement(this.dateSituationDebut,this.dateSituationFin);
      this.iconSearch="pi pi-spin pi-spinner";
      this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
    }
  //Debut ------------------------------recap paiement Client -----------------------
  ventes() {
    this.Vente.action = "RecapByClient";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapClient(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => {
          this.tableVentess = x;
          if(this.tableVentess.length>0){this.paiementRecapp()}      
        }
      );
  }
  paiementRecapp() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.soldesInitial()));
  }
  soldesInitial() {
    this.Paiement.action = "soldeInitial";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapSoldeInitial = x,
        // console.log(x),
        this.avoirRecap()));
  }
  avoirRecap() {
    this.Avoir.action = "SelectRecapByIdClient";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoir(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirs = x,
        this.avoirAchatRecap()));
  }
  avoirAchatRecap() {
    this.Avoir.action = "SelectRecapByIdFournisseurClient";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirAchats = x,
        // console.log(x),
        this.achatRecap()));
  }
  achatRecap() {
    this.Achat.action = "SelectRecapByIdClient";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapAchats = x,
        // console.log(x),
        this.tablePaiement()));
  }
  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableVentess.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentess[index].avance = 0;
            this.tableVentess[index].reste = this.tableVentess[index].total;  
          }else{
              this.tableVentess[index].avance = updateItem.montant;
              this.tableVentess[index].reste = this.tableVentess[index].total-updateItem.montant;
          }
        });
    } 
    this.tableSoldeInitial();
  }
  tableSoldeInitial(){
    if (this.tableRecapSoldeInitial.length!=undefined){
        this.tableVentess.forEach((row ,index )=>{
          let updateItem = this.tableRecapSoldeInitial.find(item=>item.idClient==row.idClient);
          // if(row.idClient==1) {
          //   console.log(updateItem);
          // }
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentess[index].soldeInitial = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentess[index].soldeInitial = updateItem.montant;
          this.tableVentess[index].reste += -updateItem.montant;      
          }
        }); 
    }
    let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
    // console.log(rechercheClient);
    if(rechercheClient){
      this.Vente.idClient = rechercheClient.id;
    }
    this.exclut(this.Vente.idClient);
    this.tableAvoir();
  }
  tableAvoir(){
    if (this.tableRecapAvoirs.length!=undefined){
        this.tableVentess.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirs.find(item=>item.idClient==row.idClient);
          if (updateItem===undefined){
            this.tableVentess[index].avoir = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentess[index].avoir = updateItem.montant;
          this.tableVentess[index].reste += -updateItem.montant;      
          }
        }); 
    }
    this.tableAvoirAchat();
  }
  tableAvoirAchat(){
    if (this.tableRecapAvoirAchats){
      // console.log(this.tableRecapAvoirAchats);
        this.tableVentess.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentess[index].avoirAchat = 0;
            
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentess[index].avoirAchat = updateItem.montant;
          this.tableVentess[index].reste += updateItem.montant;      
          }
        }); 
    }else{
      this.tableVentess.forEach((row ,index )=>{
        this.tableVentess[index].avoirAchat = 0;
      });
    }
    this.tableAchat();
  }
  tableAchat(){
    if (this.tableRecapAchats.length!=undefined){
        this.tableVentess.forEach((row ,index )=>{
          let updateItem = this.tableRecapAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentess[index].achat = 0;
          }else{
          this.tableVentess[index].achat = updateItem.total;
          this.tableVentess[index].reste += -updateItem.total;      
          }
        }); 
        // console.log(this.tableVentess);
    }
    // 
  }
  //FIN   ------------------------------recap paiement Client -----------------------

  imageLoaded :{};
  onImageError(images) {
    this.imageLoaded[images] = false;
  }

  encaisser(){
    this.displayDialogPaiement=true;
    if(!this.Vente.montantrecus){
      this.Vente.montantrecus=0;
      this.MontantRendu();
    }
    // this.tableAvance.idModePaiement=1;
    // this.displayModePaiement=true;
  }
    searchArticle(){
      let filtered = this.Articles.filter((x) => 
        x.designation.toString().toLocaleLowerCase().includes(this.designationSearch.toLocaleLowerCase())
      );   
      this.filteredArticles = filtered;
    }
    getArticles() {
      // this.Article.action = 'Select';
      // this.data.stock(this.Article).toPromise().then((x: Article[]) => (this.stock = x));
      this.blockedDocument=true;
      this.Article.action = 'SelectRecapDepot';
      this.Article.depot  = 'magasin';
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data.stockDepot(this.Article).toPromise().then((x: Article[]) => (this.stock = x,this.blockedDocument=false));
    }
    filterArticle(event) {
      // let filtered : any[] = [];
      // let query = event.query;
      let query = event.query;
      // console.log(event);
      let filtered = this.Articles.filter((x) => x.designation.toString().toLocaleUpperCase().includes(query.toLocaleUpperCase())); 
      //let  filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));
      // console.log(filtered);
      this.showTop10=false;
      this.rechercheArticlee = filtered;
      this.filteredArticles = filtered;
    }
    filterCodeBare(event) {
      let filtered : any[] = [];
      let query = event.query;
      filtered = this.Articles.filter((x) => x.codeBarre.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
      this.filteredCodeBare = filtered;
      this.rechercheArticlee = filtered;
    }
    filterReference(event) {
      let filtered : any[] = [];
      let query = event.query;
      filtered = this.Articles.filter((x) => x.reference.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
      this.filteredReference = filtered;
    }
  
    showDialogCharge(){
      this.displayCharge=true;
    }
    MontantRendu(){
      if(!this.Vente.remise){
        this.Vente.remise=0;
      }
      this.Vente.reste=this.totalMontant*1 - this.Vente.montantrecus*1 - this.Vente.remise*1 ;
      this.Vente.reste=this.Vente.reste.toFixed(2);
    }
    solder(){
      this.Vente.montantrecus=this.totalMontant-this.Vente.remise;
      this.MontantRendu();
    }

    showDialogModePaiement(){
      this.displayModePaiement=true;
      this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
      this.tableAvance.idModePaiement=1;
    }
    getDataTable() {
      this.Article.action = "Select";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data.getData(this.Article).subscribe(data => {
        // console.log(data);
        this.tableData = data.data;
        this.dtOptions = {
          data: this.tableData,
          columns: [
            {title: 'ID', data: 'id'},
            {title: 'Désignation', data: 'label'},
          ]
        };
      });
    }
    tactile(){
      this.Tactile.action = "Select";
      this.Tactile.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Tactile.idUser  = this.AuthenticationService.currentUserValue.id;
      let datatable="tactile";
      this.data
        .donnee(datatable,this.Tactile)
        .toPromise()
        .then((x: Tactile[]) => {
          this.Tactiles = x;
          if (this.Tactiles.length<1) {
          // PRICE
          this.Tactile.priceHide=true;
          this.Tactile.priceBackGroundColor="white";
          this.Tactile.priceColor="#333333";
          this.Tactile.pricePosition='Center';
          this.Tactile.priceDhs='Dhs';
          this.Tactile.priceFontSize=12;
          this.Tactile.priceTop=80;
          this.Tactile.priceLeft=0;
          this.Tactile.priceWidth=50;
          // STOCK
          this.Tactile.stockHide=true;
          this.Tactile.stockBackGroundColor="white";
          this.Tactile.stockColor="#333333";
          this.Tactile.stockPosition='Center';
          this.Tactile.stockFontSize=12;
          this.Tactile.stockTop=80;
          this.Tactile.stockLeft=75;
          this.Tactile.stockWidth=50;
          // DESIGNATION
          this.Tactile.designationHide=true;
          this.Tactile.designationEllipsis=true;
          this.Tactile.designationBackGroundColor="white";
          this.Tactile.designationColor="#333333";
          this.Tactile.designationPosition='Center';
          this.Tactile.designationFontSize=12;
          this.Tactile.designationTop=50;
          this.Tactile.designationHeight=25;
          this.Tactile.designationLeft=0;
          this.Tactile.designationWidth=100;          
          // BON
          this.Tactile.bonWidght=35;
          this.Tactile.photoWidth=150;
          this.Tactile.photoHeight=100;
          this.Tactile.bonFavorisHight=35;
          }else{
            // console.log(this.Tactiles);
            this.Tactile = this.Tactiles.find((x) => x.idUser == this.AuthenticationService.currentUserValue.id);
          }
          // console.log(this.Tactile);          
        });
    }
  
    article() {
      this.blockedDocument=true;
      this.Article.action = "SelectRecapDepot";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .article(this.Article)
        .toPromise()
        .then((x: Article[]) => (
          this.Articles = x,
          // console.log(x),          
          this.filteredArticles = x,
          this.ArticlesRecherche = x,
          this.famille()
          ));
    }
    
    famille(){
      this.ModePaiement.action = "Select";
      this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      let datatable="type";
      this.data
        .donnee(datatable,this.ModePaiement)
        .toPromise()
        .then((x: ModePaiement[]) => (this.Types = x, this.articleFavoris()));    
    }
    articleFavoris() {
      this.Article.action = "Select";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .favoris(this.Article)
        .toPromise()
        .then((x: Article[]) => (
          this.ArticlesFavoris = x,this.top10day()
          // console.log(x)          
          ));
    }
    top10day(){
      this.showTop10=true;
      this.Article.action = "SelectTopTenDay";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      let datatable="article";
      this.data
        .donnee(datatable,this.Article)
        .toPromise()
        .then((x: Article[]) => (
          this.ArticlesTop10 = x,this.blockedDocument=false
          // console.log(x)          
          ));
    }
    generateArticle(){
      this.Articles.forEach((row,index)=>{
        if(!row.codeBarre) {this.Articles[index].codeBarre=""};
        if(!row.reference) {this.Articles[index].reference=""};
      });
    }
  
    // achat() {
    //   this.Achat.action = "Select";
    //   this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    //   this.data
    //     .achat(this.Achat)
    //     .toPromise()
    //     .then((x: Achat[]) => (this.Achats = x));
    // }
    paiementDetail() {
      this.Paiement.action = "paiemenetParIdCommande";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .paiementClient(this.Paiement)
        .toPromise()
        .then((x: Paiement[]) => (this.tableDetailPaiements = x,this.calculTotalAvance()));
    }
    // getPaiement() {
    // 	this.Paiement.action = 'paiemenetParIdCommande';
    // 	this.data.paiementClient(this.Paiement).toPromise().then((x: Paiement[]) => (this.recherchePaimentIdCommandes = x,console.log(x)));
    // }
    onEnter() {}
    rechercheDesignation(idArticle) {
      // this.rechercheArticle = this.Achats.find((x) => x.idArticle === idArticle);
      // this.tableCommandeVente.designation = this.rechercheArticle.designation;
    }
    testPrix(qte){
      // alert(this.quantiteVenteGros +" | "+this.prixVenteDetail)
      var Qte = parseFloat(qte);
      // if(Qte>=this.quantiteVenteGros){
      //   this.tableCommandeVente.prixVente=this.prixVenteGros;
      // }else if(Qte>=this.quantiteVenteDetail){
      //   this.tableCommandeVente.prixVente=this.prixVenteDetail;
      // }else{
      //   this.tableCommandeVente.prixVente=this.prixVente1;
      // }
      this.tableCommandeVente.prixVente=this.prixVente1;
    }
    @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
    setFocusDesignation(){
      setTimeout(() => this.inputDesignation.nativeElement.focus());
    }
    
    setDropdownArticle(){
      // alert('kkk');
      this.dropdownArticle=true;
      if(this.Vente.cloture==false){
        // this.setFocusDesignation();
        this.autoCompleteObject.focusInput();
      }
    }
    activatePrice:boolean=false;
    changeValueArticle(data,typee) {
      // console.log(data);
      this.idArticle = data.idArticle;
      this.blockedDocumentt = true;
      this.type = data.type;
      this.typee = typee;
      if(typee=="qte"){
        this.activatePrice=false;
        this.currentNumber = data.quantite;
        // this.prixVente = data.prixVente;
        this.quantite = data.quantite;
      }else{
        this.activatePrice=true;
        this.currentNumber = data.prixV;
        this.prixVente = data.prixV;
      }
      this.designation = data.designation.toLocaleUpperCase();
    }

    findPrixByDataBase() {
    // console.log(this.Vente.idClient + this.Vente.DataBase + this.tableCommandeVente.idArticle);
      
      if(this.Vente.idClient && this.Vente.DataBase && this.tableCommandeVente.idArticle){
        this.tableDetailVentess=[];
        this.Vente.idArticle=this.tableCommandeVente.idArticle;
        this.Vente.action = "SelectDetailParClientArticle";
        this.Vente.DBase  = this.Vente.DataBase;
        // console.log( this.Vente);
        this.data
          .vente(this.Vente)
          .toPromise()
          .then((x: Vente[]) => {
              this.tableDetailVentess = x;
              this.historyPrice='' ;
              this.venteX = 0;
              this.tableDetailVentess.forEach((row)=>{
                // this.historyPrice += row.dateCommande + '-->'+ row.prixVente;
                this.venteX = row.prixVente;
                // console.log("venteX" + this.venteX);
              }); 
            }
            );
      }else{
        this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: "Choisir un client ET un article"});
      }
    }
    findPrix(idArticle,colone) {
        // console.log(idArticle,colone);
        // alert(typeof idArticle);
        // alert(colone);
        // console.log(this.stock);    
      if (idArticle!=undefined || this.tableCommandeVente.autocompleteCodeBarre!=undefined){
        this.dropdownArticle=false;
  
        if(typeof idArticle =="string" && colone=="codeBarre-"){
          idArticle=this.tableCommandeVente.autocomplete;
          colone="codeBarre";        
        }else{    
          colone="codeBarre-";
        }
        // console.log(idArticle,colone);
        if (idArticle && this.idArticleTest!=idArticle){
          if (this.idArticleTest!=idArticle){
            this.idArticleTest=idArticle;
            this.setFocusQte();
          }
        }
          this.tableCommandeVente.stock             = 0;
          this.tableCommandeVente.idArticle         = idArticle.id; 
          this.tableCommandeVente.designation       = idArticle.designation;
          this.tableCommandeVente.prixAchat         = idArticle.prixAchat;
          this.tableCommandeVente.prixVenteGros     = idArticle.prixVenteGros;
          this.tableCommandeVente.prixVenteDetail   = idArticle.prixVenteDetail;
          this.tableCommandeVente.prixVente         = idArticle.prixVente;
          this.prixAchat                            = idArticle.prixAchat;
          this.prixVenteGros                        = idArticle.prixVenteGros;
          this.prixVenteDetail                      = idArticle.prixVenteDetail;
          this.quantiteVenteGros                    = idArticle.quantiteVenteGros;
          this.quantiteVenteDetail                  = idArticle.quantiteVenteDetail;
          this.prixVente1                           = idArticle.prixVente;
          // if(this.typeVente=='comptoir'){
          //   this.tableCommandeVente.prixVente = idArticle.prixVente;
          // }else  if(this.typeVente=='detail'){
          //   this.tableCommandeVente.prixVente = idArticle.prixVenteDetail;
          // }else  if(this.typeVente=='gros'){
          //   this.tableCommandeVente.prixVente = idArticle.prixVenteGros;
          // } 
          // console.log(colone);
        if(colone=="codeBarre"){
          this.rechercheArticle = this.stock.find((x) => x.codeBarre == idArticle);
          this.tableCommandeVente.autocomplete = this.rechercheArticle; 
        }else if(colone=="designation"){
          this.rechercheArticle = this.stock.find((x) => x.designation == idArticle.designation);
          this.tableCommandeVente.autocomplete = idArticle; 
        }else {
          // console.log(this.stock);
          this.rechercheArticle = this.stock.find((x) => x.id === idArticle.idArticle*1);
          this.tableCommandeVente.autocomplete = idArticle; 
        }
          // console.log(this.tableArticlestock) ;
          if(this.rechercheArticle){
            let rechercheArticleStock = this.stock.filter((x) => x.id == this.rechercheArticle.id);
            this.tableArticlestock = rechercheArticleStock;
  
            this.tableCommandeVente.stock = this.tableArticlestock.map((t) => (t.stockTotal))
            .reduce((a, value) => a + value, 0);
            if(this.tableCommandeVente.stock<=0){
              this.messageService.add({severity:'warn', summary: 'المخزون', detail: 'إقل من الصفر !!'});
            }
          }else{
            this.tableCommandeVente.stock =0;
          }
  
          let idArtic=this.artilcesClient.filter((x) => x.idArticle == this.tableCommandeVente.idArticle);
          
          if(typeof idArtic !== 'undefined' && idArtic.length > 0){
            let idPrixVentX = Math.max.apply(Math, idArtic.map(function(o) { return o.id; }));
            this.artilceClient = idArtic.find((x) => x.id == idPrixVentX); 
            this.venteX = this.artilceClient.prixVente;     
          }else{
            this.venteX = 0;
          }
          // console.log(this.venteX);
      }
    }
    changePrice(){
      // console.log(this.typeVente);  
      //   var typeVente='';
      // if(this.typeVente==true){
      //   typeVente='comptoir';
      // }else{
      //   typeVente='gros';
      // }
      // console.log(typeVente);
      // console.log(this.requetteCommandeVentes);
      if (this.requetteCommandeVentes.length!=undefined){
        this.requetteCommandeVentes.forEach((row ,index )=>{
          let updateItem = this.Articles.find(item=>item.idArticle==row.idArticle);
          // console.log(updateItem);          
          let prixVente=0;
            if(this.typeVente=='comptoir'){
              prixVente = updateItem.prixVente;
              // console.log(prixVente);              
            }else  if(this.typeVente=='detail'){
              prixVente = updateItem.prixVenteDetail;
            }else  if(this.typeVente=='gros'){
              prixVente = updateItem.prixVenteGros;
            }          
          // console.log(updateItem);
          if (updateItem!=undefined){
            this.requetteCommandeVentes[index].prixV = prixVente;
            this.requetteCommandeVentes[index].total = prixVente*this.requetteCommandeVentes[index].quantite;
            this.requetteCommandeVentes[index].marge = (prixVente - this.requetteCommandeVentes[index].prixAchat)*this.requetteCommandeVentes[index].quantite;
            this.totalQantite();
            // this.requetteCommandeVentes[index].prixV = updateItem.prixVente*this.requetteCommandeVentes[index].quantite;        
          }
        });        
      }
    }
    client() {
      this.Client.action = "Select";
      this.Client.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .client(this.Client)
        .toPromise()
        .then((x: Client[]) => (this.Clients = x,  this.clientNbreBon()))
    }
    clientNbreBon(){
      this.Vente.action = "SelectClient";
      this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .vente(this.Vente)
        .toPromise()
        .then((x: Vente[]) => (this.ClientsBon = x,this.users() ));
    }
    users() {
      this.User.action = "Select";
      this.User.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee('user',this.User)
        .toPromise()
        .then((x: User[]) => (
          this.Users = x,

          this.exclut("1")
          ));
          // console.log(this.Clients);
          let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
          // console.log(rechercheClient);
        if(rechercheClient){
          this.Vente.idClient = rechercheClient.id;
        }
    }
    revendeurs() {
      this.Revendeur.action = "Select";
      this.Revendeur.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee('commercial',this.Revendeur)
        .toPromise()
        .then((x: User[]) => (this.Revendeurs = x));
    }
    exclut(idClient){
      if(idClient){
        this.reste=0;
        this.prepareCommandeDetail();
        if(this.ClientsBon){
          let rechercheNbre = this.ClientsBon.find((x) => (x.idClient == idClient));
          if(rechercheNbre){
              this.nbreBon =rechercheNbre.Nbre;
          }else{
              this.nbreBon = 0;
          }                
        }else{
          this.nbreBon = 0;
        }
        this.comptoir=false;
        let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
        if(rechercheClient){
          let idClientF = rechercheClient.id;
          if(idClientF==idClient)
          this.comptoir=true;
          // alert(idClientF +'|'+idClient)
        }
        // console.log(this.Clients);
        let recherchee = this.Clients.find((x) => x.id == idClient);
        if(recherchee){
          // console.log(recherchee);
          this.Exclut         = recherchee.exclut;
          this.nomDepot       = recherchee.nom;
          this.Vente.nom      = recherchee.nom;
          this.Vente.adresse  = recherchee.adresse;
          this.Vente.ville    = recherchee.ville;
          this.Vente.plafond  = recherchee.plafond;
        }
        // alert(this.nomDepot);
        // console.log(this.Depots);
        let rechercheDepot = this.Depots.find((x) => x.designation == this.nomDepot);
        this.tableDepot = rechercheDepot;
        if(this.tableDepot){
          this.idDepotVente=this.tableDepot.idDepotVente
        }else{
          this.idDepotVente=3;
        }
  
        let rechercheUser = this.Users.find((x) => x.id == this.AuthenticationService.currentUserValue.id);
        if(rechercheUser){
          // this.Vente.abreviation  = rechercheUser.abreviation;
        }
        
        // alert(this.idDepotVente);
        if(this.tableDetailVentes){
          this.artilcesClient = this.tableDetailVentes.filter((x) => x.idClient == idClient);
        }
        if(this.tableVentess.length>0){
          let recherche = this.tableVentess.find((x) => (x.idClient == idClient));
          if(recherche){
            if(recherche.reste){
              this.reste=recherche.reste.toFixed(2);
            }
          }          
        }
      }
    }
    
    vente(dateDebut,dateFin) {
      // if ( this.depots=="Ventes"){
      //   this.depots="Depots"
      //   this.Vente.idDepot = " = 0";
      // }else if ( this.depots=="Depots"){
      //   this.depots="Ventes"
      //   this.Vente.idDepot = " <> 0"; 
      // }
      // this.depots="Depots";
      // console.log( this.Vente);
      
      this.requetteCommandeVentes=[];
      if(this.able){
        this.Vente.action = "Select";
        this.Vente.depot  = "magasin";
        this.Vente.dateDebut =dateDebut;
        this.Vente.dateFin =dateFin;
        this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      }else{
        this.Vente.action = "SelectVenteUser";
        this.Vente.depot  = "magasin";
        this.Vente.dateDebut =dateDebut;
        this.Vente.dateFin =dateFin;
        this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.Vente.idUser = this.AuthenticationService.currentUserValue.id;
      }
      this.data
        .vente(this.Vente)
        .toPromise()
        .then(
          (x: Vente[]) => (
            this.Ventes = x,
            this.tableVentes = x,
            // console.log(x),
            this.filterCommande(this.dateSituationDebut,this.dateSituationFin),
            this.iconSearch = "pi pi-search"
          )
        );
    }
    filterCommande(dateDebut,dateFin){
      if (this.nom==undefined){this.nom='';}
      if (this.nomComplet==undefined){this.nomComplet='';}
      if (this.revendeur==undefined){this.revendeur='';}
      if (this.nbon==undefined){this.nbon='';}
      if (this.numm==undefined){this.numm='';}
      if (this.nomAr==undefined){this.nomAr='';}
      // console.log(dateDebut +" / "+ dateFin + " / " + this.nom + " / " + this.nomComplet+ 
      // " / " + this.nbon+   " / " + this.numm)
      // console.log(this.Ventes);
      if (this.Ventes){
        let recherche = this.Ventes.filter((x) => 
         (x.dateCommande >= dateDebut && x.dateCommande <= dateFin) && 
          x.nom.toLocaleLowerCase().includes(this.nom.toLocaleLowerCase()) &&
          x.nomAr.toLocaleLowerCase().includes(this.nomAr.toLocaleLowerCase()) &&
          x.nomComplet.toLocaleLowerCase().includes(this.nomComplet.toLocaleLowerCase()) &&
          x.revendeur.toLocaleLowerCase().includes(this.revendeur.toLocaleLowerCase()) &&
          x.nbon.toString().includes(this.nbon) && 
          x.numm.toString().includes(this.numm)
          );
          // console.log(recherche);
          
        this.tableVentes = recherche; 
        if(recherche!= undefined){
          this.charge(dateDebut,dateFin);
          this.paiementRecap(dateDebut,dateFin);
          // this.recherhceMarge();
          this.totalCommande();
        }
      }
  
        // let recherche = this.Ventes.filter((x) => (
        //   x.dateCommande >= dateDebut && x.dateCommande <= dateFin) 
        //   );
        // if (recherche){
        //   this.tableVentes = recherche; 
        //   this.totalCommande();
        //   this.charge(dateDebut,dateFin);
        //   this.paiementRecap(dateDebut,dateFin);
        // }
    }
    totalCommande() {
      this.total = 0;
      this.total = this.tableVentes.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
      this.remise = 0;
      this.remise = this.tableVentes.map((t) => t.remise * 1).reduce((a, value) => a + value, 0);
      this.margeTotal = 0;
      this.margeTotal = this.tableVentes.map((t) => t.marge * 1).reduce((a, value) => a + value, 0);
    }
    depot() {
      this.Depot.action = "SelectDepot";
      this.Depot.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .depot(this.Depot)
        .toPromise()
        .then((x: Depot[]) => (this.Depots = x));
    }
    findDepot(idDepot) {   
      // console.log(idDepot);
      // console.log(this.Depots);
       
      let rechercheDepot = this.Depots.find((x) => x.id == idDepot);
      // this.tableDepot = rechercheDepot;
      // console.log(rechercheDepot);
      
      this.tableCommandeVente.depot = rechercheDepot.designation;
      // this.idDepotVente=this.tableDepot.idDepotVente
    }
    findModePaiement(idModePaiement) {
      let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
      this.tableAvance.modePaiement = rechercheModePaiement.modePaiement;
    }
    modePaiement() {
      this.ModePaiement.action = "Select";
      this.ModePaiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .modePaiement(this.ModePaiement)
        .toPromise()
        .then((x: ModePaiement[]) => (this.ModePaiements = x));
    }
    charge(dateDebut,dateFin) {
      this.Charge.action = 'FilterChargeCaisse';
      this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Charge.dateDebut = dateDebut;
      this.Charge.dateFin   = dateFin;
      this.data
        .charge(this.Charge)
        .toPromise()
        .then(
          (x: Charge[]) => (this.Charges = x, this.calculTotalCharge())
        );
    }
    
    calculTotalCharge(){
      this.totalCharge = 0;    
      if(this.Charges.length>0){
        this.totalCharge = this.Charges
          .map((t) => t.montant * 1)
          .reduce((a, value) => a + value, 0);  
      }
    }
    
    calculTotalAvance(){
      this.totalAvance = 0;    
      if(this.tableAvances.length>0){
        this.totalAvance = this.tableAvances
          .map((t) => t.montant * 1)
          .reduce((a, value) => a + value, 0);  
      };
      this.prepareCommandeDetail();
    }
    paiementRecap(dateDebut,dateFin) {
      this.Paiement.action = "RecapByDate";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.dateDebut = dateDebut;
      this.Paiement.dateFin   = dateFin;  
      this.data
        .paiementClient(this.Paiement)
        .toPromise()
        .then((x: Paiement[]) => (this.Paiements = x,this.calculTotalPaiement()));
    }
    calculTotalPaiement() {
      this.totalPaiement = 0;    
      if(this.Paiements.length>0){
        this.totalPaiement = this.Paiements
          .map((t) => t.montant * 1)
          .reduce((a, value) => a + value, 0);  
      }  
    }
    venteDetail(dateDebut,dateFin) {
      // console.log(dateDebut+dateFin);
      
      this.Vente.action = "SelectDetail";
      this.Vente.depot = 'magasin';
      this.Vente.dateDebut =dateDebut;
      this.Vente.dateFin =dateFin;
      this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .vente(this.Vente)
        .toPromise()
        .then((x: Vente[]) => (
          this.tableDetailVentes = x, 
          this.vente(this.dateSituationDebut,this.dateSituationFin)
          ));
    }
    // this.recherhceMarge()
    figer(){
      if (this.afficher!=true){
        this.afficher=true;
      }else{
        this.afficher=false;
      }
    }
    cloture(idCommande,cloture) {
      // alert(idCommande+" | "+cloture);
      let etat;
      if (cloture==0){
        etat=1;
      }else{
        etat=0;
      }
        this.blockedDocument=true;
        this.tableCommandeVente.action = "UpdateCloure";
        this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.tableCommandeVente.etat = etat;
        this.tableCommandeVente.id = idCommande;
        this.data
          .vente(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              // alert(res.message);
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.initialiserBon();
              this.vente(this.dateSituationDebut,this.dateSituationFin);
              this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
            } else {
              alert(res.message);
            }
          });
    }
  
    transformDecimal(num) {
      return this._decimalPipe.transform(num, "1.2-2");
    }
    // recherhceMarge(){
    //       this.tableVentes.forEach((row)=>{
    //       let recherche = this.tableDetailVentes.filter((x) => x.idCommande == row.idCommande);
    //       let updateItem = this.tableVentes.find(item=>item.idCommande==row.idCommande);
    //       let index = this.tableVentes.indexOf(updateItem);
    //       let marge =  JSON.parse(JSON.stringify(recherche))
    //         .map((t) => t.marge)
    //         .reduce((a, value) => a + value, 0);
    //       this.tableVentes[index].marge = marge;
    //     }); 
    //     this.totalCommande();
    // }
    saveCommandeVente() {
      this.tableCommandeVente.action = "Insert";
      this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.tableCommandeVente.commandeDetail = this.commandeDetail;
     
      this.data
        .vente(this.tableCommandeVente)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.vente(this.dateSituationDebut,this.dateSituationFin);
            alert(res.message);
          } else {
            alert(res.message);
          }
        });
    }
    //   filter(data, colone) {
    //     console.log(data + colone);
    //     let recherche = this.Achats.filter(
    //       (x) =>
    //         x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
    //     );
    //     this.tableAchats = recherche;
    //     this.total = this.tableAchats
    //       .map((t) => t.montant * 1)
    //       .reduce((a, value) => a + value, 0);
    //   }
    filter(data, colone) {
      let recherche;
      if (colone=="nbon" ){
         recherche = this.Ventes.filter((x) => x.nbon.toString().includes(data));
      }else if ( colone =="numm"){
        recherche = this.Ventes.filter((x) => x.numm.toString().includes(data));
      }else{
        recherche = this.Ventes.filter(
        (x) =>
          x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
      ); 
      }
      if (recherche!=undefined){
      this.tableVentes = recherche;
      }
      this.totalCommande();
    }
    formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
  
      return [year, month, day].join('-');
  }
  
    
  
    barcode(){
      this.displaybarcode=true;
      this.close();
      // this.Vente.nbon='0';
      // this.Vente.remise=0;
      // setTimeout(() => this.inputEl.nativeElement.focus());
    }
  
    codebarre(){
      this.close();
      if (this.afficherCodebarre==true){
        this.afficherCodebarre=false;
        this.displaybarcode=false;
      }else{
        this.afficherCodebarre=true;
        this.displaybarcode=true;
      }
      // this.Vente.idClient='1';
      let rechercheClient = this.Clients.find((x) => x.nom.toLocaleLowerCase() == "COMPTOIR");
      if(rechercheClient){
        this.Vente.idClient = rechercheClient.id;
      }
      this.tableCommandeVente.idDepot=1;
      // this.setFocus();
      // this.displaybarcode=false;
    }
    // @ViewChild('input1', {static: false}) inputEl:ElementRef;
    // setFocus(){
    //   setTimeout(() => this.inputEl.nativeElement.focus());
    // }
    @ViewChild('inputCodeBarre', {static: false}) inputCodeBarre:ElementRef;
    setFocusCodeBarre(){
      setTimeout(() => this.inputCodeBarre.nativeElement.focus());
    }  
    @ViewChild('inputDesignationCodeBarre', {static: false}) inputDesignationCodeBarr:ElementRef;
    setFocusDesignationCodeBarre(){
      setTimeout(() => this.inputDesignationCodeBarr.nativeElement.focus());
    }  
    setFocusArticle(){
      // setTimeout(() => this.inputArticle.nativeElement.focus());
    }  
    @ViewChild('inputQte', {static: false}) inputQte:ElementRef;
    setFocusQte(){
      setTimeout(() => this.inputQte.nativeElement.focus());
    }
    @ViewChild('inputAvance', {static: false}) inputAvance:ElementRef;
    setFocusAvance(){
      setTimeout(() => this.inputAvance.nativeElement.focus());
    }
    @ViewChild('printBtn') printBtn: ElementRef<HTMLElement>;
    barcodeHide(){
      this.displaybarcode=false;
    }
    ticketPrint(event){
      this.Vente=event;
      let recherche = this.tableDetailVentes.filter(
        (x) => x.id == event.idCommande
      );
      
      this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.totalQantite();
      this.cdr.detectChanges();
      // console.log(this.Vente);
      
      var htmlContent = document.getElementById('print-section-ticket').innerHTML.replace("xxx","");
      var htmlContent = htmlContent.replace("yyy","");
      var htmlContent = htmlContent.replace("ccc","");
      var htmlContent = htmlContent.replace("ttt",'<ngx-barcodebc-value="{{Vente.numm}}" [bc-width]="1.2" [bc-height]="25" [bc-display-value]="false" [bc-font-size]="10" ></ngx-barcode>');  
      var printWindow = window.open("", "Print");
      printWindow.document.write(htmlContent);
      printWindow.print();
      printWindow.close();
      this.Vente={};
      this.tableCommandeVentes=[];
      this.requetteCommandeVentes=[];
      let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
      // console.log(rechercheClient);
      if(rechercheClient){
        this.Vente.idClient = rechercheClient.id;
      }
    }
    bonPrint(event){
      if(this.tableCommandeVentes.length==1){
        this.higthCol='500px';
      }else if(this.tableCommandeVentes.length>1) {
        let hight=500-(this.tableCommandeVentes.length-1)*20;
        this.higthCol=hight + 'px';
      }
      this.Vente=event;
      let recherche = this.tableDetailVentes.filter(
        (x) => x.id == event.idCommande
      );
      
      this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.totalQantite();
      this.cdr.detectChanges();
      // console.log(this.Vente);
      
      var htmlContent = document.getElementById('print-section').innerHTML.replace("xxx","");
      var htmlContent = htmlContent.replace("yyy","");
      var htmlContent = htmlContent.replace("ccc","");
      var htmlContent = htmlContent.replace("ttt",'<ngx-barcodebc-value="{{Vente.numm}}" [bc-width]="1.2" [bc-height]="25" [bc-display-value]="false" [bc-font-size]="10" ></ngx-barcode>');  
      var printWindow = window.open("", "Print");
      printWindow.document.write(htmlContent);
      printWindow.print();
      printWindow.close();
      this.Vente={};
      this.tableCommandeVentes=[];
      this.requetteCommandeVentes=[];
      let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
      // console.log(rechercheClient);
      if(rechercheClient){
        this.Vente.idClient = rechercheClient.id;
      }   
    }
    factureSelect(event){
      this.displayDialog = false; 
      this.displaybarcode=true;       
      this.Vente.id = event.idCommande;
      this.Vente.num = event.num;
      this.Vente.numm = event.numm;
      this.Vente.dateCommandee = event.dateCommande;
      this.Vente.idClient = JSON.stringify(event.idClient);
      this.Vente.remise = event.remise;
      this.Vente.nom = event.nom;
      this.Vente.nbon = event.nbon;
      this.Vente.idUser = event.idUser;
      this.Vente.nomComplet = event.nomComplet;
      this.Vente.cloture = event.cloture;
      // alert (this.Vente.idClient);
      let recherche = this.tableDetailVentes.filter(
        (x) => x.id == event.idCommande
      );
      // console.log(recherche);
      
      this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.totalQantite();
      this.prepareCommandeDetail();
      // this.setFocus();
      this.afficherCodebarre=true;
      this.displaybarcode=true;
    }

    saveParametre() {
      // console.log(this.Tactile.id);
      
      if (!this.Tactile.id) {
        this.Tactile.action = "Insert";
        let datatable="tactile";
        this.Tactile.DBase  = this.AuthenticationService.currentUserValue.DBase;
        // console.log(this.Tactile);
        this.data
          .donnee(datatable,this.Tactile)
          .toPromise()
          .then((res: { message: string}) => {
            // console.log(res);
            if (res.message == "Opération de modification réuissie") {
              this.tactile();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            }
          });        
      }else{
        this.Tactile.action = "Update";
        let datatable="tactile";
        this.Tactile.DBase  = this.AuthenticationService.currentUserValue.DBase;
        // console.log(this.Tactile);
        this.data
          .donnee(datatable,this.Tactile)
          .toPromise()
          .then((res: { message: string}) => {
            // console.log(res);
            if (res.message == "Opération de modification réuissie") {
              this.tactile();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            }
          });
      }
 
    }
    save() {
      this.prepareCommandeDetail();
      if (this.Vente.nbon=='0' ){
        this.Vente.nbon=this.Vente.nbon.toString();
      }
      // console.log('idclient' + this.Vente.idClient);
      // console.log('nbon' + this.Vente.nbon);
      // console.log('commandeDetail' + this.commandeDetail);
      if (this.Vente.idClient && this.commandeDetail!='') {
      this.blockedDocument=true;
      if (this.Vente.id) {
        // alert('update');
        this.tableCommandeVente.action = "Update";      
        this.tableCommandeVente.DBase  = this.AuthenticationService.currentUserValue.DBase;
        if (this.Vente.idUser){
          this.tableCommandeVente.idUser              = this.Vente.idUser;
        }else{
            this.tableCommandeVente.idUser            = this.AuthenticationService.currentUserValue.id;
        }
        this.tableCommandeVente.commandeDetail        = this.commandeDetail;
        this.tableCommandeVente.commandePaiement      = this.commandePaiement ;
        this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
        this.tableCommandeVente.prixArticle           = this.prixArticle;
        this.tableCommandeVente.nom                   = this.Vente.nom;
        this.tableCommandeVente.id                    = this.Vente.id;
        this.tableCommandeVente.num                   = this.Vente.num;
        this.tableCommandeVente.numm                  = this.Vente.numm;
        this.tableCommandeVente.remise                = this.Vente.remise;
        this.tableCommandeVente.montantrecus          = this.Vente.montantrecus;
        this.tableCommandeVente.idDepot               = this.Vente.idDepot;
        this.tableCommandeVente.idDepotVente          = this.idDepotVente;
        this.tableCommandeVente.idClient              = this.Vente.idClient;
        this.tableCommandeVente.idRevendeur           = this.Vente.idRevendeur;
        this.tableCommandeVente.nom                   = this.nomDepot;
        this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
        this.tableCommandeVente.dateTicket            = this.Vente.dateTicket;
        this.tableCommandeVente.nbon                  = this.Vente.nbon;
        this.tableCommandeVente.observation           = this.Vente.observation;
        // this.tableCommandeVente.nbreBon            = this.nbreBon;
        // console.log(this.tableCommandeVente);
        this.data
          .vente(this.tableCommandeVente)
          .toPromise()
          .then((res: { message: string,numm:number ,dateTicket:string}) => {
            // console.log(res);
            if (res.message == "Opération de modification réuissie") {
              this.Vente.numm = res.numm;
              this.cdr.detectChanges();
              let el: HTMLElement = this.printBtn.nativeElement;
              el.click();
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
  
              // this.vente(this.dateSituationDebut,this.dateSituationFin);
              // this.getArticles();
              this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
              this.paiementDetail();
              this.initialiserBon();
              this.requetteCommandeVentes=[];
              this.tableCommandeVentes=[];
              this.displayDialogPaiement  = false;
              this.displaybarcode         = false;
              this.blockedDocument        = false;
              this.displayDialog          = false;
              this.desablePrint           = false;
            } else {
              alert(res.message);
            }
          });
      } else {
        // alert('add')
          this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
          this.tableCommandeVente.action                = "Insert";
          this.tableCommandeVente.DBase                 = this.AuthenticationService.currentUserValue.DBase;
          this.tableCommandeVente.idUser                = this.AuthenticationService.currentUserValue.id;
          this.tableCommandeVente.remise                = this.Vente.remise;
          this.tableCommandeVente.commandeDetail        = this.commandeDetail;
          this.tableCommandeVente.commandePaiement      = this.commandePaiement ;
          this.tableCommandeVente.commandeDetailDepot   = this.commandeDetailDepot  ;
          this.tableCommandeVente.prixArticle           = this.prixArticle;
          this.tableCommandeVente.idDepot               = this.Vente.idDepot;
          this.tableCommandeVente.idDepotVente          = this.idDepotVente;
          this.tableCommandeVente.remise                = this.Vente.remise;
          this.tableCommandeVente.montantrecus          = this.Vente.montantrecus;
          this.tableCommandeVente.idClient              = this.Vente.idClient;
          this.tableCommandeVente.idRevendeur           = this.Vente.idRevendeur;
          this.tableCommandeVente.nom                   = this.nomDepot;
          this.tableCommandeVente.dateCommandee         = this.Vente.dateCommandee;
          this.tableCommandeVente.nbon                  = this.Vente.nbon;
          this.tableCommandeVente.nbreBon               = this.nbreBon;
          this.tableCommandeVente.observation           = this.Vente.observation;
          // console.log(this.tableCommandeVente);
          this.data
            .vente(this.tableCommandeVente)
            .toPromise()
            .then((res: { message: string,numm:any,abreviation:string,dateTicket:string }) => {
              if (res.message == "Opération de modification réuissie") {
                this.Vente.numm         = res.numm;
                this.Vente.abreviation  = res.abreviation;
                this.Vente.dateTicket   = res.dateTicket;
                this.barcodeValue       = this.Vente.numm;
                this.cdr.detectChanges();
                let el: HTMLElement = this.printBtn.nativeElement;
                el.click();
                // this.vente(this.dateSituationDebut,this.dateSituationFin);
                // this.getArticles();
                this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
                this.paiementDetail();
                this.initialiserBon();
              

                this.requetteCommandeVentes=[];
                this.tableCommandeVentes=[];
                this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
                this.displayDialogPaiement   = false;  
                this.displaybarcode   = false;  
                this.displayDialog    = false;
                this.blockedDocument  = false;
                this.desablePrint     = false;
                this.displaybarcode   = false;
              } else {
                alert(res.message);
                this.displayDialog = false;
              }
            });
        }
      } else {
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Vous-avez oublier le clients !!!"});
      }
    }
    delete() {
      // alert();
      //   this.confirmationService.confirm({
      //       message: 'Do you want to delete this record?',
      //       header: 'Delete Confirmation',
      //       icon: 'pi pi-info-circle',
      //       accept: () => {
        if (confirm("êtes vous sure?")) {
          // console.log(this.Vente);
              this.Vente.action = "Delete";
              this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
              this.data
                .vente(this.Vente)
                .toPromise()
                .then((res: { message: string }) => {
                  if ((res.message = "Opération de supression réuissie")) {
                    this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
                    this.vente(this.dateSituationDebut,this.dateSituationFin);
                    this.venteDetail(this.dateSituationDebut,this.dateSituationFin);
                    this.displayDialog = false;
                  } else {
                    alert("Nonnnn");
                  }
                });
              // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
                
            // },
            // reject: () => {
            //     // this.messageService.add({severity:'info', summary:'Rejected', detail:'لقذ تم حذف'});
            //     this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
            // }
        // });
      //
      // });
    }
    }
    
    exportexcel(): void {
    let fileName = "Situation des ventes du "+ this.dateSituationDebut +  " au " +  this.dateSituationFin+".xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title =
        "Situation du " +
        this.dateSituationDebut +
        " au " +
        this.dateSituationFin;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        "du " + this.dateSituationDebut + " au " + this.dateSituationFin
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
    printSituation(): void {
      var params = [
        "height=" + screen.height,
        "width=" + screen.width,
        "fullscreen=yes", // only works in IE, but here for completeness
      ].join(",");
      let printContents, popupWin;
      printContents = document.getElementById("print-table").innerHTML;
      popupWin = window.open("", "_blank", params);
      popupWin.document.open();
      popupWin.document.write(`
          <html>
          <head>
            <title>Print tab</title>
            <style>
            table {
            // width: 750px;
            border-collapse: collapse;
            margin: 5px auto;
          }				
          /* Zebra striping */
          tr:nth-of-type(odd) {
            // background: #eee;
            
          }				
          th {
            background: #3498db;
            color: white;
            font-weight: bold;
            text-align: center;
          }				
          td,
          th {
            padding: 5px;
            // border: 1px solid #ccc;
            text-align: left;
            font-size: 12px;
          }
            </style>
          </head>
        <body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
          </html>`);
      popupWin.document.close();
    }
    printBL(idCommande) {
      this.displayDialog = false;
      // console.log(event);
      // this.Vente.id = event.data.idCommande;
      // this.Vente.dateCommandee = event.data.dateCommande;
      // this.Vente.idClient = event.data.idClient;
      // this.Vente.remise = event.data.remise;
  
      let recherche = this.tableDetailVentes.filter((x) => x.id == idCommande);
      // console.log(recherche);      
      this.tableCommandeVentes = JSON.parse(JSON.stringify(recherche));
      this.requetteCommandeVentes = JSON.parse(JSON.stringify(recherche));
      // console.log(this.requetteCommandeVentes);
      this.totalQantite();
      // this.prepareCommandeDetail();
    }
    newDonnee: boolean;
    showDialog() {
      this.newDonnee = true;
      this.Vente = {};
      this.displayDialog = true;
    }
    showDialogToAdd() {
      this.afficher= true;
      this.showButon = false;
      this.showButonSauvgarder = false;
      this.showButonAvance = false;
      this.newDonnee = true;
      this.tableAvances = [];
      this.totalAvance=0;
      this.Vente = {};
      this.Vente.remise = 0;
      this.tableCommandeVente = {};
      this.tableCommandeVentes = [];
      this.requetteCommandeVentes = [];
      this.tableAvance ={};
      this.tableAvances =[];
      this.tableClient ={};
      this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
      this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
      // this.Vente.idClient='1';
      this.tableCommandeVente.idDepot=1;
      // this.setFocusCodeBarre();
      if(this.Vente.cloture==false || this.Achat.cloture==undefined ){
        // this.setFocusCodeBarre();
        this.autoCompleteObject.focusInput();
      }
      let rechercheDB =this.Donnees.find((x) => x.actif == true);
      if(rechercheDB){
        this.Vente.DataBase= rechercheDB.data;
      }
      this.displayDialog = true;
      this.exclut("1");
    }
  	// selectArticle(data) {
    // console.log(data);

    //   // let recherhce = this.vente.filter((x) => x.id == data.id);
    //   // alert(this.rechercheArticle.length)	;
    //   if (recherhce.length>0){
    //     alert:("l'article selectionné est déja dans la liste ! \n\t"+ data.designation  +" \t\n السلعة المختارة متواجة في الفاتورة")	;
    //   }else{
    //     // alert(data.prixVente + ' | ' + data.prixVente10 + ' | ' + data.prixVente100)
    //   //   this.vente.push(data);
    //   //   this.blockedDocument=true;
    //   //   this.prixVente100=data.prixVente100;
    //   //   this.prixVente10=data.prixVente10;
    //   //   this.prixVente=data.prixVente;
    //   //   this.idArticle=data.id;
    //   }
    // }
    addArticle(data) {
      // console.log(data);
      // var prixAchat =data.prixAchat*1;
      // var prixVente =data.prixVente*1;
      // if(prixVente <= prixAchat){
      //   this.colorInput='red';
      //   this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
      // }else{
        if(this.typeVente=='comptoir'){
            this.prixVente = data.prixVente;
          }else  if(this.typeVente=='detail'){
            this.prixVente = data.prixVenteDetail;
          }else  if(this.typeVente=='gros'){
            this.prixVente = data.prixVenteGros;
          } 
        this.colorInput='';
        if(data.idArticle && data.idDepot && data.quantite && this.prixVente && this.prixVente>0){
            this.findDepot(data.idDepot);
            // alert(data.idDepot);
            // alert( data.prixAchat);
            // console.log(this.showButon);
            if(this.showButon==false){
              if( data.hasOwnProperty('prixAchat')==false){
                data.prixAchat=0;
              }
              data.prixV=this.prixVente;
              data.total=data.prixV*data.quantite;
              data.marge=(data.prixV - data.prixAchat)*data.quantite;
              if(this.tableCommandeVente.autocomplete){
                data.codeBarre=this.tableCommandeVente.autocomplete.codeBarre;
                data.reference=this.tableCommandeVente.autocomplete.reference;
              }
              if (this.tableCommandeVente.idArticle || data.codeBarre || data.idArticle ) {
                this.tableCommandeVentes.unshift(data);
                this.showButon = false;
                this.nbre=this.tableCommandeVentes.length-1;
                if(this.tableCommandeVentes.length==1){
                  this.higthCol='500px';
                }else if(this.tableCommandeVentes.length>1) {
                  let hight=500-(this.tableCommandeVentes.length-1)*20;
                  this.higthCol=hight + 'px';
                }
                this.totalQantite();
                // this.prepareCommandeDetail();
                this.tableCommandeVente = {};
                this.selectedArticle="";
                this.tableCommandeVente.idDepot=data.idDepot;
              } else {
                alert("Entrer un Article !");
              }
              this.desablePrint=true;
              this.setFocusArticle();
              // var index = this.tableCommandeAchats.findIndex((x) => x.index == index);
            }else{
              if(this.tableCommandeVente.prixVente<this.prixAchat){
                this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
                this.tableCommandeVente.prixVente=this.prixAchat;
              }else{
              // 
                if( data.hasOwnProperty('prixAchat')==false){
                  data.prixAchat=0;
                }

                this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
                this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
                this.tableCommandeVentes[this.index].depot            = data.depot;
                this.tableCommandeVentes[this.index].designation      = data.designation;
                this.tableCommandeVentes[this.index].quantite         = data.quantite;
                this.tableCommandeVentes[this.index].remise           = data.remise;
                this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
                this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
                this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
                this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
                this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
                this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
                this.tableCommandeVentes[this.index].codeBarre        = this.tableCommandeVente.autocomplete.codeBarre;
                this.tableCommandeVentes[this.index].reference        = this.tableCommandeVente.autocomplete.reference;
                this.idClient                                         = this.tableCommandeVente.idClient;
                this.showButon = false;
                this.totalQantite();
                // this.prepareCommandeDetail();
            }
          }
        }else{
          this.playAudio("المرجوا الانتباه الثمن 0");
          // this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "المرجوا الانتباه شيء ما ناقص!!!"});
        }      
      // }
    }
  
    updateArticle(data) {
      if((data.prixVente*1) <= (this.prixAchat*1)){
        this.colorInput='red';
        this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: "Prix de vente est inférieur au prix d'achat !!!"});
      }else{
        if( data.hasOwnProperty('prixAchat')==false){
          data.prixAchat=0;
        }
        
        if(data.idArticle && data.idDepot && data.quantite && data.prixVente){
          this.tableCommandeVentes[this.index].idArticle        = data.idArticle;
          this.tableCommandeVentes[this.index].idDepot          = data.idDepot;
          this.tableCommandeVentes[this.index].depot            = data.depot;
          this.tableCommandeVentes[this.index].designation      = data.designation;
          this.tableCommandeVentes[this.index].quantite         = data.quantite;
          this.tableCommandeVentes[this.index].remise           = data.remise;
          this.tableCommandeVentes[this.index].prixAchat        = data.prixAchat;
          this.tableCommandeVentes[this.index].prixVente        = data.prixVente;
          this.tableCommandeVentes[this.index].prixVenteGros    = data.prixVenteGros;
          this.tableCommandeVentes[this.index].prixVenteDetail  = data.prixVenteDetail;
          this.tableCommandeVentes[this.index].total            = data.prixVente*data.quantite;
          this.tableCommandeVentes[this.index].marge            = (data.prixVente-data.prixAchat)*data.quantite;
          this.idClient                                         = this.tableCommandeVente.idClient;
          this.showButon = false;
          this.totalQantite();
          // this.prepareCommandeDetail();
          this.findDepot(data.idDepot);
          this.tableCommandeVente.idDepot=data.idDepot;
          
        }else{
          this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
        }
      }
    }
    addAvance(data) {
      //  console.log(data);
      if(data.idModePaiement && data.montant){
          this.findModePaiement(data.idModePaiement);
          if(this.showButonAvance==false){
              this.tableAvances.push(data);
              this.showButonAvance = false;
              this.calculTotalAvance();
              this.tableAvance = {};
              this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
              this.tableAvance.idModePaiement=1;
          }else{
            // alert(data.idModePaiement);
              this.tableAvances[this.indexAvance].idModePaiement    = data.idModePaiement;
              this.tableAvances[this.indexAvance].montant           = data.montant;
              this.tableAvances[this.indexAvance].modePaiement      = data.modePaiement;
              this.tableAvances[this.indexAvance].dateEcheance      = data.dateEcheance;
              this.tableAvances[this.indexAvance].reference         = data.reference;
              this.showButonAvance                                  = false;
              // this.totalQantite();
              this.calculTotalAvance();
              this.tableAvance = {};
              this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
              this.tableAvance.idModePaiement=1;
          }
      }else{
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      }
    }
    
    addClient(data) {
      //  console.log(data);
      if(data.nom){
          if(this.showButonClient==false){
              this.tableClient.action = "InsertVente";
              this.tableClient.DBase  = this.AuthenticationService.currentUserValue.DBase;
              this.data
                .client(this.tableClient)
                .toPromise()
                .then((res: { message: string }) => {
                  if (res.message == "Opération de modification réuissie") {
                    // alert(res.message);
                    this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
                    this.displayDialogClient  = false;  
                    this.blockedDocument      = false;
                    this.client();
                    this.nom="";
                  } else {
                    this.messageService.add({severity:'warn', summary: 'Attention !!! ', detail: res.message});
                  }
                });
            } else {
              this.tableClient.action = "Update";
              this.tableClient.DBase  = this.AuthenticationService.currentUserValue.DBase;
              this.data
                .client(this.tableClient)
                .toPromise()
                .then((res: { message: string }) => {
                  if (res.message == "Opération de modification réuissie") {
                    // alert(res.message);
                    this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
                    this.displayDialogClient  = false;  
                    this.blockedDocument      = false;
                    this.client();
                    this.nom="";
                  } else {
                    alert(res.message);
                  }
                });
            }
      }else{
        this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "Attention des Vides فارغ"});
      }
    }
    desabledBlockedDocumentt(){
      // console.log('dddd');
      this.blockedDocumentt=false;
    }
    
    addArticleBarCode(data,colonne){
      // console.log(data);
      this.bon=false;
      let recherche:any;
      if (colonne=="codeBarre"){
        recherche = this.Articles.find((x) => x.codeBarre == data);
      }else if (colonne=="idArticle"){
        recherche = this.Articles.find((x) => x.idArticle == data.idArticle);
      }else{
        recherche = this.Articles.find((x) => x.designation == data.designation);
      }
      //  console.log(recherche);
      if(!recherche){
        this.playAudio(data);
      }else{
        data           = recherche;
        // console.log(data);
        if(this.typeVente=='comptoir'){
          data.total     = recherche.prixVente;
          data.marge     = (recherche.prixVente-recherche.prixAchat);
        }else  if(this.typeVente=='detail'){
          data.total     = recherche.prixVenteDetail;
          data.marge     = (recherche.prixVenteDetail-recherche.prixAchat);
        }else  if(this.typeVente=='gros'){
          data.total     = recherche.prixVenteGros;
          data.marge     = (recherche.prixVenteGros-recherche.prixAchat);
        } 

        data.idArticle  = recherche.idArticle;
        data.remise     = 0;
        data.nbon       = 0;
        let exist       = this.requetteCommandeVentes.find((x) => x.idArticle == data.idArticle);
        if(!exist){
          // console.log('exist');
          data.quantite=1;
          data.idDepot=1;
          this.addArticle(data);
          // this.setFocus();
          // this.requetteCommandeVentes.reverse();
          this.designation=data.designation;
          this.quantite=1;
        }else{
          var index = this.tableCommandeVentes.findIndex((x) => x.idArticle == data.idArticle);
          this.tableCommandeVentes[index].quantite=exist.quantite+1;
          if(this.typeVente=='comptoir'){
            this.tableCommandeVentes[index].total = recherche.prixVente*this.tableCommandeVentes[index].quantite;
          }else  if(this.typeVente=='detail'){
            this.tableCommandeVentes[index].total = recherche.prixVenteDetail*this.tableCommandeVentes[index].quantite;
          }else  if(this.typeVente=='gros'){
            this.tableCommandeVentes[index].total = recherche.prixVenteGros*this.tableCommandeVentes[index].quantite;
          }             
          if(this.prixVente==0){
            this.playAudio("المرجوا الانتباه الثمن 0");
            // this.messageService.add({severity:'warn', summary: 'Atterntion !!!', detail: "المرجوا الانتباه الثمن 0!!!"});
          }
          this.designation=this.tableCommandeVentes[index].designation;
          this.quantite=this.tableCommandeVentes[index].quantite;
          this.tableCommandeVentes.splice(0, 0, this.tableCommandeVentes.splice(index, 1)[0]);
          this.totalQantite();
          // this.prepareCommandeDetail();
          this.tableCommandeVente = {};
          this.selectedArticle="";
        }
        this.selectedCommandeDetail = this.tableCommandeVentes[0];
      }
      this.showButon = false;
      this.BarCode="";
    }
  
    playAudio(data){
      let audio = new Audio();    
      audio.src = this.myUrl+'/API/API/uploads/alert.mp3';
      audio.load();
      audio.play();
      if(data=='المرجوا الانتباه الثمن 0'){
        this.messageService.add({severity:'error', summary: 'Attention !!!', detail: '!!! '+data});
      }else{
        this.messageService.add({severity:'error', summary: 'Attention !!!', detail: 'Article non truové !!! '+data});
      }
      
    }
    changeView(){
      if(this.changeImage){
        this.icon="images";
        this.changeImage=false;
      }else{
        this.icon="pencil";
        this.changeImage=true;
      }
    }
    findArticle(idArticle) {
      let recherche = this.Articles.find((x) => x.id == idArticle);
      this.designation = recherche.designation;
      this.idArticle = idArticle;
      // alert(this.idArticle);
      // if (recherche != undefined) {
      // 	return recherche.designation;
      // } else {
      // 	return '0';
      // }
    }
    showCLient(){
      this.displayDialogClient = true;
    }
    totalQantite() {
      let totalMontant = 0;
      let totalMarge = 0;
      let totalQte = 0;
      for (let data of this.tableCommandeVentes) {
        totalMontant += data.total;
        totalMarge += data.marge * 1;
        totalQte += data.quantite * 1;
      }
      this.totalQte = totalQte;
      this.totalMarge = totalMarge;
      this.totalMontant = totalMontant.toFixed(2);
      // return this._decimalPipe.transform(total, '1.2-2');
      // return total.toFixed(2);      
      this.MontantRendu();      
      this.prepareCommandeDetail();
    }
  
    deleteAvance(idModePaiement) {
      var index = this.tableAvances.findIndex((x) => x.idModePaiement == idModePaiement);
      this.tableAvances.splice(index, 1);
      this.calculTotalAvance();
    }
    deleteCommande(idDetail,idArticle) {
      // console.log(this.tableCommandeVentes);
      // alert(idDetail+'||'+idArticle);
      this.desablePrint=true;
      if (idDetail !=undefined){
        var index = this.tableCommandeVentes.findIndex(
          (x) => x.idDetail == idDetail 
        );
      }else{
       var index = this.tableCommandeVentes.findIndex(
          (x) => x.idArticle == idArticle
        );      
      }
      this.tableCommandeVentes.splice(index, 1);
      this.totalQantite();
      // this.setFocus();
    }
  
    prepareCommandeDetail() {
      // console.log(this.tableCommandeVentes);
      //--------Détail Commande---------

      this.commandeDetail = "";
      for (let data of this.tableCommandeVentes) {
        if(this.typeVente=='comptoir'){
          this.prixVente = data.prixVente;
        }else  if(this.typeVente=='detail'){
          this.prixVente = data.prixVenteDetail;
        }else  if(this.typeVente=='gros'){
          this.prixVente = data.prixVenteGros;
        }
        // (idCommande,idDepot,idArticle,remise,quantite,prixAchat,prixVente)
        this.commandeDetail +=
          "('idCommande','" +
          data.idDepot      + "','" +
          data.idArticle    + "','" +
          // data.remise       + "','" +
          data.quantite     + "','" +
          data.prixAchat +  "','" + 
          this.prixVente +
          "'),";
      }
      this.commandeDetail += "$";
      this.commandeDetail = this.commandeDetail.replace(",$", ";");
  
      //--------Détail Paiement---------
      this.commandePaiement = "";
      // idCommade	idClient	dateReglement	montant	modePaiement	reference	dateEcheance	regle	annule	
      var idClient =0;      
      let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
      if(rechercheClient){
        idClient = rechercheClient.id;
      }
      // console.log(this.Vente.idClient+' | '+idClient);
      
      if(this.Vente.idClient==idClient){
        this.commandePaiement           =
              "('idCommande','"         +
              this.Vente.idClient       + "','" +
              this.Vente.dateCommandee  + "','" +
              (this.totalMontant)       + "','1','','','o'),";
              // (this.totalMontant - this.Vente.remise) + "','1','','','o'),";
      }else{
        // console.log(this.Vente.montantrecus);
        // console.log(this.totalMontant);
        if(this.Vente.montantrecus>=this.totalMontant){
          this.commandePaiement         +=
            "('idCommande','"         +
            this.Vente.idClient       + "','" +
            this.Vente.dateCommandee  + "','" +
            (this.totalMontant)       + "','1','','','o'),";        
        }else if(this.Vente.montantrecus>0){
          this.commandePaiement         +=
            "('idCommande','"         +
            this.Vente.idClient       + "','" +
            this.Vente.dateCommandee  + "','" +
            (this.Vente.montantrecus) + "','1','','','o'),";            
        }
      }
      this.commandePaiement += "$";
      this.commandePaiement = this.commandePaiement.replace(",$", ";");
      this.commandePaiement = this.commandePaiement.replace("undefined", "");
  //-----------------vers Variation du Stock ----------------//
  
  this.commandeDetailDepot = "";
  for (let data of this.tableCommandeVentes) {
    this.commandeDetailDepot +=
      "('idCommande','"         +
      this.Vente.dateCommandee  +  "','" +
      data.idDepot              +  "','" +
      data.idArticle            +  "','" +
      data.quantite             +  
      "','Vente'),";
  }
  this.commandeDetailDepot += "$";
  this.commandeDetailDepot = this.commandeDetailDepot.replace(",$", ";");
  
      //Achat Camion //prixAchat,prixVenteGros, prixVenteDetail,prixVente
      let prixArticleAchat= "";
      let prixArticleVenteGros= "";
      let prixArticleVenteDetail= "";
      let prixArticleVente= "";
      let prixArticle= "";
      this.prixArticle= "UPDATE article ";
  
      //--------vers Article ---------
      var str =  " WHERE id in (" + prixArticle + ") ";
      str = str.replace(", )", ")");
      this.prixArticle += 
      " SET prixAchat     = (case " + prixArticleAchat + " end)," + 
      " prixVenteGros     = (case " + prixArticleVenteGros + " end)," +  
      " prixVenteDetail   = (case " + prixArticleVenteDetail + " end)," +  
      " prixVente         = (case " + prixArticleVente + " end)" +  
      str;
      this.prixArticle=this.prixArticle.replace("undefined", "0"); 
      // console.log(this.prixArticle );
      this.initialiser();
      // alert(this.tableCommandeVente.idFournisseur);
    }
  
    onRowSelect(event) {
      // console.log(event);
      // console.log(this.Vente.cloture);
      if(event.cloture==0){
        this.afficher= false;
        this.bon= false;
        // if (!event.data.cloture){
          // this.AuthenticationService.currentUserValue.username= this.AuthenticationService.currentUserValue.username.toUpperCase();
          // if (this.able) {
            // this.displayDialog = true;
            this.artilcesClient=[];
            this.artilceClient={}; 
            this.tableAvances=[];
            this.tableAvance={}; 
            this.Vente.id = event.idCommande;
            this.Vente.num = event.num;
            this.Vente.numm = event.numm;
            this.Vente.nbreBon = event.nbreBon;
            // alert(this.Vente.nbreBon);
            this.Vente.dateCommandee  = event.dateCommande;
            this.Vente.dateTicket     = event.dateTicket;
            // document.getElementById("print-section-ticket").innerHTML =htmlContent;
            let rechercheRevendeur = this.Revendeurs.find((x) => x.nom == event.revendeur);
            if(rechercheRevendeur==undefined){
              this.Vente.idRevendeur=0;
            }else{
              this.Vente.idRevendeur = rechercheRevendeur.id;
            }
            let rechercheDB =this.Donnees.find((x) => x.actif == true);
            if(rechercheDB){
              this.Vente.DataBase= rechercheDB.data;
            }
            // let vvv=JSON.stringify(event.data.idClient);
            
            this.Vente.idClient     = JSON.stringify(event.idClient);
            this.Vente.idDepot      = event.idDepot;
            this.Vente.remise       = event.remise;
            this.Vente.montantrecus = event.montantrecus;
            this.Vente.nom          = event.nom;
            this.Vente.nbon         = event.nbon;
            this.Vente.idUser       = event.idUser;
            this.Vente.nomComplet   = event.nomComplet;
            this.Vente.adresse      = event.adresse;
            this.Vente.ville        = event.ville;
            this.Vente.abreviation  = event.abreviation;
            this.Vente.codeClient   = event.codeClient;
            this.Vente.codeClient   = event.codeClient;
            this.Vente.observation  = event.observation;
            // console.log(this.Vente.nomComplet.substring(0, 1));
            this.Vente.cloture      = event.cloture;
            this.Exclut             = event.exclut;
            this.tableCommandeVente.idDepot=1;
            this.showButonAvance    = false;
            // alert (this.Vente.idClient);
            // console.log(this.tableDetailVentes);
    
            let recherche = this.tableDetailVentes.filter((x) => x.id == event.idCommande);
            
            if(recherche){
              this.tableCommandeVentes      = JSON.parse(JSON.stringify(recherche));
              this.requetteCommandeVentes   = JSON.parse(JSON.stringify(recherche));
              // this.requetteCommandeVentes.forEach((row,index)=>{
              //   this.requetteCommandeVentes[index].prixV = row.prixVente;
              //   console.log(this.requetteCommandeVentes[index].prixV);                
              // }); 
              // console.log(this.requetteCommandeVentes);
              this.nbre=this.tableCommandeVentes.length-1;
              if(this.tableCommandeVentes.length==1){
                this.higthCol='500px';
              }else if(this.tableCommandeVentes.length>1) {
                let hight=500-(this.tableCommandeVentes.length-1)*20;
                this.higthCol=hight + 'px';
              }
              this.requettesCommandeVentes = JSON.parse(JSON.stringify(this.requetteCommandeVentes));
              // console.log(this.requettesCommandeVentes);
    
              this.requettesCommandeVentes.forEach((row,index)=>{
                this.Familles.forEach((rowFamilles)=>{
                  var regEx = new RegExp(rowFamilles.type, "ig");
                  this.requettesCommandeVentes[index].designation=row.designation.replace(regEx,' ');
                });
                // console.log(this.requettesCommandeVentes);
               })
              this.paiementDetail();
              this.tableAvances = [];
              this.totalAvance=0;
              // console.log(typeof event.data);
              // console.log(this.tableDetailPaiements);
              // console.log(typeof this.tableDetailPaiements);
              if(this.tableDetailPaiements){
                let rechercher    = this.tableDetailPaiements.filter((x) => x.idCommande == event.idCommande);
                
                if(rechercher){
                  this.tableAvances = rechercher;
                }
                // console.log(rechercher);
              }
    
              this.calculTotalAvance();     
              this.totalQantite();
              this.prepareCommandeDetail();
              this.exclut(event.idClient);
    
              // console.log(this.Vente);          
            }
    
          // }
        // }
      }else{
        this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: 'Bon Verrouillé ...'});
      }

    }
    cloneDonnee(c: Vente): Vente {
      let Vente = {};
      for (let prop in c) {
        Vente[prop] = c[prop];
      }
      return Vente;
    }
    onRowSelectAvance(event) {
      
      this.tableAvance.idModePaiement   = event.data.idModePaiement;
      this.tableAvance.modePaiement     = event.data.modePaiement;
      this.tableAvance.montant          = event.data.montant;
      this.tableAvance.dateEcheance     = event.data.dateEcheance;
      this.tableAvance.reference        = event.data.reference;
      this.showButonAvance              = true;
      this.showButonSauvgarder          = true;
      this.indexAvance = this.tableAvances.findIndex((x) => x.idModePaiement == event.data.idModePaiement && x.montant ==event.data.montant);
      this.setFocusAvance();
    }
    onRowSelectCommandeDetail(event) {
      // console.log(event.data);
      this.setFocusArticle();
      this.rechercheArticle = this.Articles.find((x) => x.id == event.data.idArticle);
      this.quantiteVenteGros                    = this.rechercheArticle.quantiteVenteGros;
      this.quantiteVenteDetail                  = this.rechercheArticle.quantiteVenteDetail;
      this.prixAchat                            = this.rechercheArticle.prixAchat;
      this.prixVenteGros                        = this.rechercheArticle.prixVenteGros;
      this.prixVenteDetail                      = this.rechercheArticle.prixVenteDetail;
      this.prixVente1                           = this.rechercheArticle.prixVente;
      this.designation                          = this.rechercheArticle.designation;
      this.showButon = true;
      this.showButonSauvgarder = true;
      this.dropdownArticle=false;
      this.index = this.tableCommandeVentes.findIndex(
        (x) => x.idArticle == event.data.idArticle
      );
      // // alert(this.index);
      this.newDonnee = false;
      this.tableCommandeVente = this.cloneDonneeCommandeDetail(event.data);
      // if(typeof event.data.autocompleteCodeBarre =="object"){
      //   this.tableCommandeVente.autocompleteCodeBarre=event.data.autocompleteCodeBarre;
      // }else{
        this.tableCommandeVente.autocompleteCodeBarre=event.data;
      // }
      // if(typeof event.data.autocomplete =="object"){
      //   this.tableCommandeVente.autocomplete=event.data.autocomplete;
      // }else{
        this.tableCommandeVente.autocomplete=event.data;
      // }
      this.displayDialog = true;
      // console.log( this.tableCommandeVente);
    }
    cloneDonneeCommandeDetail(c: Vente): Vente {
      let tableCommandeVente = {};
      for (let prop in c) {
        tableCommandeVente[prop] = c[prop];
      }
      return tableCommandeVente;
    }
    onRowSelectBarreCode(event){
      // console.log(event);
      // alert(event.data.idArticle);
      this.idArticle=event.data.idArticle;
      this.currentNumber='0';
      this.blockedcalculator=true;
    }
    close() {
      this.setFocusArticle();
      this.displayDialog = false;
      this.displayCharge = false;
      this.tableCommandeVentes = [];
      this.tableCommandeVente = {};
      this.Charge={};
      this.Charges=[];
      this.requettesCommandeVentes = [];
      this.requetteCommandeVentes = [];
      this.requetteCommandeVente = {};
      this.Vente.id=null;
      this.Vente.idClient="";
      this.Vente.dateCommandee = new Date()
      .toISOString()
      .split("T")[0];
      this.tableCommandeVente.dateCommandee = new Date()
        .toISOString()
        .split("T")[0];
      this.totalQantite();
      this.prepareCommandeDetail();
      this.Vente.nbon='0';
      this.Vente.remise=0;
      // setTimeout(() => this.inputEl.nativeElement.focus());
    }
    initialiserAvance() {
      this.tableAvance={};
      this.showButonAvance=false;
      this.tableAvance.idModePaiement=1;
      this.tableAvance.dateEcheance = new Date().toISOString().split("T")[0];
    }
    initialiserBon() {
      // if (confirm("هل أنت متأكد ؟")) {
        this.requetteCommandeVentes = [];
        this.tableCommandeVentes = [];
        this.Vente = {};
        this.Vente.autocomplete = {};
        this.totalMontant=0;
        this.totalQte=0;
        this.tableCommandeVente.dateCommandee = new Date().toISOString().split("T")[0];
        this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
        let rechercheClient = this.Clients.find((x) => x.nom.toLocaleUpperCase() == "COMPTOIR");
        if(rechercheClient){
          this.Vente.idClient = rechercheClient.id;
          this.Exclut         = rechercheClient.exclut;
          this.nomDepot       = rechercheClient.nom;
          this.Vente.nom      = rechercheClient.nom;
          this.Vente.adresse  = rechercheClient.adresse;
          this.Vente.ville    = rechercheClient.ville;
          this.Vente.plafond  = rechercheClient.plafond;
        }
        if(this.tableVentess.length>0){
          let recherche = this.tableVentess.find((x) => (x.idClient == rechercheClient.id));
          if(recherche){
            this.reste=recherche.reste.toFixed(2);
          }          
        // } 
      }
    }
    initialiser() {
      // this.desablePrint=false;
      this.colorInput='';
      this.tableCommandeVente = {};
      this.requettesCommandeVentes = [];
      this.requetteCommandeVentes = this.tableCommandeVentes;
      this.showButon=false;
      this.prixVenteGros=undefined;
      this.prixVenteDetail=undefined;
      this.prixVente1=undefined;
      this.tableCommandeVente.idDepot=1;
      // this.setFocusCodeBarre();
      if(this.Vente.cloture==false || this.Vente.cloture==undefined ){
        // this.setFocusCodeBarre();
        this.autoCompleteObject.focusInput();
      }
      // this.tableCommandeAchat.idArticle = null;
      // this.tableCommandeAchat.quantite = "";
      // this.tableCommandeAchat.prixAchat = "";
      // this.tableCommandeAchat.prixVente = "";
      // this.tableCommandeAchat.prixVenteDetail = "";
      // this.tableCommandeAchat.prixVenteGros = "";
      // this.showButon = false;
    }
  }
  
