import { Component, ElementRef,ViewChild, OnInit,PipeTransform, Pipe } from "@angular/core";
import { DataService } from "../../data.service";
import { Article } from "../../model/article";
import { Type } from "../../model/type";
import { Tva } from "src/app/model/tva";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DecimalPipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthenticationService } from "../../authentication.service";
import * as $ from 'jquery';
import { Achat } from "src/app/model/achat";
import { Forfait } from "src/app/model/forfait";
import { tr } from "date-fns/locale";
// import {
//   trigger,
//   state,

//   style,
//   transition,
//   animate,
// } from "@angular/animations";
// @Pipe({name: 'fill'})
@Component({
  selector: "app-articlesDuplicat",
  templateUrl: "articlesDuplicat.component.html",
  styleUrls: ["articlesDuplicat.component.scss"],
  providers: [ConfirmationService,DecimalPipe],
})

export class ArticlesDuplicatComponent implements OnInit {
  //----------------------Début Parametre CODE BARRE
  elementType = 'svg';
  value = '6933256621283';
  // format = 'EAN13';
  // lineColor = '#000000';
  // width = 1.5;
  // height = 50;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  get values(): string[] {
    return this.value.split('\n');
  }
  codeList: string[] = [
    '',
    'CODE128',
    'CODE128A',
    'CODE128B',
    'CODE128C',
    'UPC',
    'EAN8',
    'EAN5',
    'EAN13',
    'EAN12',
    'EAN2',
    'CODE39',
    'ITF14',
    'MSI',
    'MSI10',
    'MSI11',
    'MSI1010',
    'MSI1110',
    'pharmacode',
    'codabar',
  ];
  //----------------------Fin Parametre CODE BARRE
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  // activite: string = "Service";
  Article: Article = {};
  Articles: Article[];
  Forfait  : Forfait = {};
  Forfaits : Forfait[];
  Favoris  : Type = {};
  Favoriss : Type[];
  tableArticle: Article[];
  tableArticleDouble: Article[];
  selectedArticle: Article;
  displayDialog: boolean;
  displayDialogCodeBarre: boolean = false;
  selectedOption: string;
  selectedType: number;
  Type: Type = {};
  Types: Type[];
  Tva: Tva = {};
  Tvas: Tva[];
  blockedDocument: boolean = false;
  able: boolean =false;
  designation: string;
  codeBarre: string;
  reference?: string;
  type: string;
  dateNow?: string;
  file?: string;
  ingredient?: string='codeBarre';
  dropdownArticle: boolean = true;
  requetteCommandeAchats: any = [];
  filteredArticles: any[];
  rechercheArticle: Article = {};
  tableCommandeAchats: any = [];
  tableCommandeAchat: Achat = {};
  commandeDetail: string;
  recherche: string;
  formatBarreCode: string;
  showButon: boolean =false;
  verifie: boolean = true;
  swichCodeBarre: boolean = false;
  quantite:number=90;
  selectedCategories:[]=[];
  constructor(
    private data: DataService, 
    private http: HttpClient,
    public AuthenticationService: AuthenticationService,
    private _decimalPipe: DecimalPipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
    ) {}

      // num:number = 70;
  ngOnInit() {
    this.dateNow = new Date().toISOString().split("T")[0];
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.getTypes();
    this.getFavoris();
    this.getTva();
    this.article();
    this.forfait();
    // $('#elemId').val("8003511428570");
  }
  transform(value) {
    return (new Array(value)).fill(1);
  }
  // getArticles() {
  // 	this.data.getArticles().toPromise().then((x: Article[]) => ((this.Articles = x), (this.tableArticle = x)));
  // }
  article() {
    // this.Article.action = "SelectStockArticle";
    // this.Article.action = "RecapDepot";
    this.Article.action = "Select";
    this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      // .stock(this.Article)
      // .stockDepot(this.Article)
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x, this.tableArticle = x));
  }
  showDoublon(){
    this.Article.action   = "SelectDoublon";
    this.Article.element  = this.ingredient;
    this.Article.DBase    = this.AuthenticationService.currentUserValue.DBase;
    this.data
      // .stock(this.Article)
      // .stockDepot(this.Article)
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => {
            this.tableArticleDouble=x;
            this.recherche = "";
            if(x.length>0){
              this.tableArticleDouble.forEach((x)=>{
                this.recherche +="'" +x.element+"',";
              });
            }
            if(x.length>0){
              this.recherche += "$";
              this.recherche = this.recherche.replace(",$", "");
              // console.log(this.recherche);
              this.Article.action = "SelectArticleIn";
              this.Article.element = this.ingredient;
              this.Article.recherche = this.recherche;
              this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
              this.data
                .article(this.Article)
                .toPromise()
                .then((x: Article[]) => {( this.Articles = x, this.tableArticle = x )}) ;
            }
        }
      );
  }
  forfait() {
    // this.Article.action = "SelectStockArticle";
    this.Forfait.action = "forfait";
    this.Forfait.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      // .stock(this.Article)
      .article(this.Forfait)
      .toPromise()
      .then((x: Article[]) => this.Forfaits = x);
  }
  
  filterArticle(event) {
    let filtered : any[] = [];
    let query = event.query;
    // console.log(this.Articles);
    filtered = this.Articles.filter((x) => x.designation.toString().toLocaleLowerCase().includes(query.toLocaleLowerCase()));   
    this.filteredArticles = filtered;
  }
  @ViewChild('inputDesignation', {static: false}) inputDesignation:ElementRef;
  setFocusDesignation(){
    setTimeout(() => this.inputDesignation.nativeElement.focus());
  }
  setDropdownArticle(){
    // alert('kkk');
    this.dropdownArticle=true;
      this.setFocusDesignation();
  }
  findPrix(idArticle,typee) {
    // console.log(idArticle);
    if (idArticle!=undefined){
      this.dropdownArticle=false;
      // if(typee=="codeBarre"){
      //   this.rechercheArticle = this.Articles.find((x) => x.codeBarre == idArticle);
      // }else if(typee=="designation"){
      //   this.rechercheArticle = this.Articles.find((x) => x.id == idArticle.idArticle);
      // }else {
        this.rechercheArticle = this.Articles.find((x) => x.id === idArticle);
      // }
      
      // this.rechercheArticle = this.Articles.find((x) => x.id === idArticle);
      this.tableCommandeAchat.idArticle       = this.rechercheArticle.idArticle;
      this.tableCommandeAchat.designation     = this.rechercheArticle.designation;
      // let recherche = this.tableCommandeAchats.filter((x) => x.idArticle == idArticle.idArticle);
      // this.requetteCommandeAchats = recherche;
    }
  }
  addArticle(data) {
    if(this.showButon==false ){
      
      if (this.tableCommandeAchat.idArticle) {
        this.tableCommandeAchats.push(data);
        this.showButon = false;
        this.prepareCommandeDetail();
        this.tableCommandeAchat = {};
        this.setFocusDesignation();
      } else {
        alert("Entrer un Article !");
      }
    }else{
      // this.tableCommandeAchats[this.index].idArticle        = data.idArticle;
      // this.tableCommandeAchats[this.index].designation      = data.designation; 
      // this.tableCommandeAchats[this.index].quantite         = data.quantite;
      // this.showButon = false;
      // this.prepareCommandeDetail();
      // console.log(this.tableCommandeAchats);
    }
    
  }
  deleteCommande(idDetail,idArticle) {

    if (idDetail !=""){
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idArticle == idArticle
      );
    }else{
      var index = this.tableCommandeAchats.findIndex(
        (x) => x.idDetail == idDetail
      );      
    }
    this.tableCommandeAchats.splice(index, 1);
    this.prepareCommandeDetail();
  }
  prepareCommandeDetail() {
    // console.log(this.tableCommandeAchats);
    this.commandeDetail = "";
    for (let data of this.tableCommandeAchats) {
      this.commandeDetail     +=
        "('"                   +
        this.Article.id       + "','" +
        data.idArticle        + "','" +
        data.quantite         + "'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    // console.log(this.commandeDetail);
  }
  getTypes() {
    this.Type.action = "Select";
    this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .type(this.Type)
      .toPromise()
      .then((x: Type[]) => (this.Types = x));
  }
  getFavoris() {
    this.Type.action = "Select";
    this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .favoris(this.Type)
      .toPromise()
      .then((x: Type[]) => (this.Favoriss = x));
  }
  getTva() {
    this.Tva.action = "Select";
    this.Tva.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .tva(this.Type)
      .toPromise()
      .then((x: Tva[]) => (this.Tvas = x));
  }
  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  favoris(idArticle,favoris) { 
    this.blockedDocument=false;
    this.displayDialog = false;
    // alert(idCommande+" | "+cloture);
    let etat;
    if (favoris==0){
      etat=1;
    }else{
      etat=0;
    }
      this.blockedDocument=true;
      this.Article.action = "UpdateFavoris";
      this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Article.favoris = etat;
      this.Article.id = idArticle;
      console.log(this.Article);
      this.data
        .article(this.Article)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.article();
          } else {
            alert(res.message);
          }
        });
  }
  // && this.Article.idType && this.Article.tva
  save() {
    if(this.Article.designation && this.Article.prixVente   ){
      this.blockedDocument=true;
      if (this.Article.id) {
        this.Article.action           = "Update";
        this.Article.commandeDetail   = this.commandeDetail;
        this.Article.DBase            = this.AuthenticationService.currentUserValue.DBase;
        this.Article.dateNow          = this.dateNow;
        this.Article.verifie          = this.verifie;
        console.log(this.Article);        
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
              this.forfait();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
            }
          });
      } else {
        this.Article.action           = "Insert";
        this.Article.DBase            = this.AuthenticationService.currentUserValue.DBase;
        this.Article.commandeDetail   = this.commandeDetail;
        this.Article.verifie          = this.verifie;
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.article();
              this.forfait();
            } else {
              this.messageService.add({severity:'warn', summary: 'Attention !!', detail: res.message});
              this.blockedDocument=false;
              // this.displayDialog = false;
            }
          });
      }
    }else{
      alert("Vous avez oublier un champ !!!")
    }
    
  }
  onRowSelectElement(event){
    console.log(event.data);
    if(this.ingredient=="A.codeBarre"){
      this.codeBarre=event.data.element;
      this.reference="";
      this.designation="";
    }else if(this.ingredient=="A.reference"){
      this.reference=event.data.element;
      this.codeBarre="";
      this.designation="";
    }else if(this.ingredient=="A.designation"){
      this.designation=event.data.element;
      this.reference="";
      this.codeBarre="";
    }
  }
  deleteDuplicat(id) {
    console.log(id);
    console.log(id.length);
    let recherche = "";
    id.forEach((x)=>{
      recherche +="'" +x+"',";
    });
    recherche += "$";
    recherche = recherche.replace(",$", "");
    console.log(recherche);
    
    if (id.length>0) {
      if (confirm("êtes vous sure?")) {
        this.displayDialog = false;
        this.blockedDocument= true;
        console.log(typeof id);
        
        if(typeof id =="object"){
          this.Article.action = "DeleteGroup";
          this.Article.recherche  = recherche;
        }else{
          this.Article.action = "Delete";
          this.Article.id     = id;
        }
        this.Article.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.Article.dateNow = new Date().toISOString().split("T")[0]; 
        // console.log(this.Article);
        this.data
          .article(this.Article)
          .toPromise()
          .then((res: { message: string }) => {
            if ((res.message = "Opération de supression réuissie")) {
              this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
              this.blockedDocument=false;
              this.displayDialog = false;
              this.showDoublon();
            } else {
              alert("Nonnnn");
            }
          });
      }
    }else{
      this.messageService.add({severity:'info', summary: 'Supression', detail: "Cocher les articles à supprimer"});
    }
    this.close();
    this.displayDialog = false;
  }

  delete() {
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Article.action = "Delete";      
      this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.Article.dateNow = new Date().toISOString().split("T")[0]; 
      // console.log(this.Article);
      this.data
        .article(this.Article)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.blockedDocument=false;
            this.displayDialog = false;
            this.article();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }
  onUpload() {
    // this.Article.chemin = this.myUrl + "/API/API/";
    // alert(this.Type.type);
    this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .posteFileArticle(
        this.Article.id,
        this.Article.designation,
        this.Article.DBase,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: data.message});
        this.article();
        this.displayDialog = false;
      });
  }
  close() {
    this.displayDialog = false;
    this.Article = {};
    this.file="";
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.file="";
   
    this.newDonnee = true;
    // this.Article.activite = 'Service';
    this.Article = {};
    this.Article.activite=false;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.newDonnee = false;
    this.file="";
    if (event.data.images == null) {
      var leet = this.myUrl + "/API/API/uploads/default.png";
    }else{
      var leet = this.myUrl + "/API/API/uploads/images/" + event.data.images;     
    }
    this.imageUrl = leet;
    this.Article = this.cloneDonnee(event.data);
    // console.log(event.data);
    let recherche = this.Forfaits.filter((x) => x.idForfait == event.data.id);
    // console.log(event.data);

    this.Article.tva = +event.data.tva; 
    this.Article.idType = +event.data.idType; 
    this.Article.idFavoris = +event.data.idFavoris; 
    this.Article.forfait = +event.data.forfait; 
    this.Article.activite = +event.data.activite; 
    this.tableCommandeAchats = recherche;
    this.requetteCommandeAchats = recherche;
    // [bc-format]='CODE128','CODE128A','CODE128B','CODE128C','UPC','EAN8','EAN5',
    // 'EAN13','EAN12','EAN2','CODE39','ITF14','MSI',
    // 'MSI10','MSI11','MSI1010','MSI1110','pharmacode','codabar',
    // console.log(event.data);
    if(event.data.codeBarre.length==13){
      this.formatBarreCode='EAN13';
    }else if(event.data.codeBarre.length==11){
      this.formatBarreCode='UPC'
    }else if(event.data.codeBarre.length==8){
      this.formatBarreCode='EAN8'
    }else{
      this.formatBarreCode='CODE128'
    }
    this.displayDialog = true;
  }
  cloneDonnee(c: Article): Article {
    let Article = {};
    for (let prop in c) {
      Article[prop] = c[prop];
    }
    return Article;
  }
}
