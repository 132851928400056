import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { Vente } from "../model/vente";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";
import { Achat } from '../model/achat';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
@Injectable()
// import mysqldump from 'mysqldump';
// mysqldump({
//   connection: {
//       host: 'localhost',
//       user: 'root',
//       password: '',
//       database: 'dregrie',
//   },
//   dumpToFile: './dump.sql',
// });
// or const mysqldump = require('mysqldump')
 
// dump the result straight to a file

@Component({
  selector: "app-accueil",
  templateUrl: "accueil.component.html",
  styleUrls: ["accueil.component.scss"],
})

export class AccueilComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  Vente: Vente = {};
  Ventes: Vente[];
  tableArticles: Achat[];
  Achat: Achat = {};
  Achats: Achat[];
  dataChartDay: any;
  dataChartPersonnel: any;
  able: boolean =false;
  admin: boolean =false;
  depotActivate: boolean =false;
  styleOBJ:any;
  // data: any;
  constructor(
    private http:HttpClient, 
    private data: DataService, 
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  performGetEx():Observable<any>{
    return this.http.get<any>(`${this.myUrl}/API/API/backup.php`);
}
public innerWidth: any;
public innerHeight: any;
public innerHeightCard: any;
public widthh: any;
  ngOnInit() {
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Admin'){
      this.admin=true;
    }
    if(this.AuthenticationService.currentUserValue.depot== true){
      this.depotActivate=true;
    }
    this.innerWidth = window.innerWidth; 
		this.innerHeight = (window.innerHeight-130) +"px";
    this.innerHeightCard = (window.innerHeight-95) +"px";
    this.widthh =  this.innerWidth *5;
    // this.styleOBJ = {'background-color':'007AD9'}
    this.styleOBJ = {'background-image':'linear-gradient(to bottom,#007AD9,#007AD0, white)'}
    // alert("innerWidth" + this.innerWidth);
		// alert("innerHeight" + this.innerHeight);
		// alert("Screen width is " + screen.width + " Screen Hieth is " + screen.height);
		// alert("Screen width is " + window.innerWidth + " Screen Hieth is " + window.innerHeight);
    // this.venteChartDay();
    // this.venteChartPersonnel();
    // this.getStock();
  }

  backup() {
    this.router.navigate([`${this.myUrl}/API/API/backup.php`]);
  }
  getStock() {
    this.Achat.action = "Stock";
    this.data
      .stock(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
          this.Achats = x,
          this.tableArticles = x,
          this.getStockVendu()
          // ,
          // console.log(x)
      ));
  }  
  getStockVendu() {
    this.Vente.action = "StockVente";
    this.data
      .stock(this.Vente)
      .toPromise()
      .then((x: Vente[]) => ((this.Ventes = x,this.addToTable())));
  }
  addToTable(){
    // this.tableArticles =this.Achats;
    // console.log(this.Ventes)
      this.Ventes.forEach((row)=>{
        // console.log(row);
        let recherche = this.tableArticles.find((x)=>x.idArticle==row.idArticle);
        if (recherche != undefined) {
          let index = this.tableArticles.indexOf(recherche);
          this.tableArticles[index].totalVente = row.totalVente;
          this.tableArticles[index].stock = this.tableArticles[index].totalAchat-row.totalVente;
        }

      }); 
      // console.log(this.tableArticles)

  }
  actionLink(lien) {
     this.router.navigate(["/"+lien]);
    // if (lien == "vente") {
    //   this.router.navigate(["/ventes"]);
    // } else if (lien == "achat") {
    //   this.router.navigate(["/achats"]);
    // } else if (lien == "stock") {
    //   this.router.navigate(["/stock"]);
    // } else if (lien == "client") {
    //   this.router.navigate(["/clients"]);
    // } else if (lien == "fournisseur") {
    //   this.router.navigate(["/fournisseurs"]);
    // } else if (lien == "article") {
    //   this.router.navigate(["/articles"]);
    // } else if (lien == "type") {
    //   this.router.navigate(["/types"]);
    // } else if (lien == "ventePro") {
    //   this.router.navigate(["/ventesPro"]);
    // }else if (lien == "situationAchat") {
    //   this.router.navigate(["/situationAchat"]);
    // }else if (lien == "situationPaiementAchat") {
    //   this.router.navigate(["/situationPaiementAchat"]);
    // }else if (lien == "situationPaiementVente") {
    //   this.router.navigate(["/situationPaiementVente"]);
    // }else if (lien == "situationsVentes") {
    //   this.router.navigate(["/situationsVentes"]);
    // }else if (lien == "devis") {
     
    // }
  }
  venteChartDay() {
    this.Vente.action = "chartDay";
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => ((this.Ventes = x), console.log(x), this.chartDay())
      );
  }
  venteChartPersonnel() {
    this.Vente.action = "chartPersonnel";
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.Ventes = x), console.log(x), this.chartPersonnel()
        )
      );
  }
  chartDay() {
    var label = [];
    var donnee = [];
    this.Ventes.forEach((value) => {
      console.log(value.label, value.data, value.nom);
      label.push(value.label);
      donnee.push(value.data);
    });
    this.dataChartDay = {
      // labels: ["January", "February", "March", "April", "May", "June", "July"],
      labels: label,
      datasets: [
        {
          label: "Chiffre d'affaire",
          backgroundColor: "#42A5F5",
          borderColor: "#1E88E5",
          data: donnee,
        },
        // {
        //   label: "My Second dataset",
        //   backgroundColor: "#9CCC65",
        //   borderColor: "#7CB342",
        //   data: [28, 48, 40, 19, 86, 27, 90],
        // },
      ],
    };
  }
  chartPersonnel() {
    var label = [];
    var nom = [];
    var donnee = [];
    var dataset = [];
    var test = "";
    var testLabel = "";
    var nomm = "";
    var i = 0;
    this.Ventes.forEach((value) => {
      //TEST LABELS
      if (testLabel == "") {
        label.push(value.label);
      } else if (testLabel != value.label) {
        label.push(value.label);
      } else {
      }
      testLabel = value.label;

      // console.log("label :" + label);

      // TEST DATA
      // if (test == '') {
      // 	donnee.push(value.data);
      // } else if (test === value.nom) {
      // 	donnee.push(value.data);
      // 	nomm = value.nom;
      // } else {
      // 	nom = [
      // 		{
      // 			label: nomm,
      // 			backgroundColor: '#42A5F5',
      // 			borderColor: '#1E88E5',
      // 			data: donnee
      // 		}
      // 	];

      // 	dataset.push(nom);
      // 	nom = [];
      // 	donnee = [];
      // 	donnee.push(value.data);
      // }
      test = value.nom;
    });

    console.log("dataset : " + dataset);
    this.dataChartPersonnel = {
      // labels: ["January", "February", "March", "April", "May", "June", "July"],
      //Axe X :
      labels: label,
      datasets: [
        // dataset,
        {
          label: "My Second dataset",
          backgroundColor: "#9CCC65",
          borderColor: "#7CB342",
          data: [28, 48, 40, 10],
        },
      ],
    };
  }
}
