import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { User } from './model/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
	
	// langue:boolean=false;
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;
	public tokenresp: any;

	constructor(private http: HttpClient) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}
	dataBase(data) {		
		// console.log(data);
		return this.http.post(`${environment.urlApi}/API/API/database.php`, data);
	}
	login(username: string, password: string, DBase: string,annee: string, francais: boolean) {
		return this.http
			.post<any>(`${environment.urlApi}/API/API/login.php`, {
				username,
				password,
				DBase,
				annee,
				francais
			})
			.pipe(
				map((user) => {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					localStorage.setItem('currentUser', JSON.stringify(user));
					this.currentUserSubject.next(user);
					return user;
				})
			);
	}
	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('currentUser');
		this.currentUserSubject.next(null);
	}
	changePassword(user) {
		return this.http.post(`${environment.urlApi}/API/API/changePassword.php`, user);
	}
	addUser(user) {
		// console.log('salut ', user);
		return this.http.post(`${environment.urlApi}/API/API/addUser.php`, user);
	}
	user(user) {
		return this.http.post(`${environment.urlApi}/API/API/user.php`, user);
	}
	deleteUser(user) {
		return this.http.post(`${environment.urlApi}/API/API/deleteUser.php`, user);
	}
	getLocalStorage(){
		return this.currentUserSubject.value;
	}
	GetToken() {
		let token = localStorage.getItem("currentUser") || '';		
		let _token = token.split('.')[2];	
		this.tokenresp = JSON.parse(atob(_token));
		return this.tokenresp.data;
	}
}
