import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DataService } from '../../data.service';
import { Charge } from '../../model/charge';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import { AuthenticationService } from '../../authentication.service';
import { MessageService } from 'primeng/api';

@Component({
	selector: 'app-charges',
	templateUrl: 'charges.component.html',
	styleUrls: [ 'charges.component.scss' ],
	providers: [ DecimalPipe ]
})
export class ChargesComponent implements OnInit {
	registerForm: FormGroup;
	// dateCharge = Date.now();
	dateSituation: Date;
	dateDebut: any;
	dateFin: any;
	Charge: Charge = {};
	Charges: Charge[];
	tableCharge: Charge[];
	selectedArticle: Charge;
	total: number;
	displayDialog: boolean;
	blockedDocument: boolean = false;
	able: boolean =false;
	constructor(
		private data: DataService,
		public AuthenticationService: AuthenticationService,
		private _decimalPipe: DecimalPipe,
		private formBuilder: FormBuilder,
		private messageService: MessageService
	) {}

	ngOnInit() {
	if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' ||
		this.AuthenticationService.currentUserValue.groupe=='Administrateurs'){
	   this.able=true;
	 }
		this.charge();
		this.dateSituation = new Date();
		var date = new Date(),
			y = date.getFullYear(),
			m = date.getMonth();
		var firstDay = new Date(y, m, 1);
		var lastDay = new Date(y, m + 1, 0);
		this.dateDebut = firstDay.toISOString().split('T')[0];
		this.dateFin = lastDay.toISOString().split('T')[0];
		this.registerForm = this.formBuilder.group({
			dateDebut: '',
			dateFin: ''
		});
	}
	charge() {
		this.Charge.action = 'Select';
		this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.data
			.charge(this.Charge)
			.toPromise()
			.then(
				(x: Charge[]) => ((this.Charges = x), (this.tableCharge = x), this.filterCommande(), this.calculTotal())
			);
	}
	save() {
		if (this.Charge.id) {
			// alert("save");
			this.Charge.action = 'Update';
			this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
			// console.log(this.Charge);
			this.data.charge(this.Charge).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.charge();
				} else {
					alert('Nonnnn');
				}
			});
		} else {
			this.Charge.action = 'Insert';
			this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
			this.data.charge(this.Charge).toPromise().then((res: { message: string }) => {
				if (res.message == 'Opération de modification réuissie') {
					this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
					this.blockedDocument=false;
					this.displayDialog = false;
					this.charge();
				} else {
					alert(res.message);
					this.displayDialog = false;
				}
			});
		}
	}
	delete() {
		if (confirm('êtes vous sure?')) {
			this.Charge.action = 'Delete';
			this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
			this.data.charge(this.Charge).toPromise().then((res: { message: string }) => {
				if ((res.message = 'Opération de supression réuissie')) {
					this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
					this.displayDialog = false;
					this.charge();
				} else {
					alert('Nonnnn');
				}
			});
		}
	}
	newDonnee: boolean;
	showDialogToAdd() {
		this.newDonnee = true;
		this.Charge = {};
		this.Charge.dateCharge = new Date().toISOString().split("T")[0];
		this.Charge.chargeFix = 0;
		this.displayDialog = true;
	}
	onRowSelect(event) {
		// console.log(event.data);
		// this.Charge.dateCharge = event.data.dateCharge;
		this.newDonnee = false;
		this.Charge = this.cloneDonnee(event.data);
		this.displayDialog = true;
	}
	cloneDonnee(c: Charge): Charge {
		let Charge = {};
		for (let prop in c) {
			Charge[prop] = c[prop];
		}
		return Charge;
	}
	filter(data, colone) {
		// console.log(data + colone);
		let recherche = this.Charges.filter(
			(x) => x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1
		);
		this.tableCharge = recherche;
		this.total = this.tableCharge.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
	}
	transformDecimal(num) {
		return this._decimalPipe.transform(num, '1.2-2');
	}
	calculTotal() {
		if (this.tableCharge) {
			this.total = this.tableCharge.map((t) => t.montant * 1).reduce((a, value) => a + value, 0);
		}
		return 0;
	}
	filterCommande() {
		this.Charge.action = 'Filter';
		this.Charge.DBase  = this.AuthenticationService.currentUserValue.DBase;
		this.Charge.dateChargee = this.dateSituation;
		this.Charge.dateDebut = this.dateDebut;
		this.Charge.dateFin = this.dateFin;
		this.data
			.charge(this.Charge)
			.toPromise()
			.then((x: Charge[]) => (this.Charges = x, this.tableCharge = x, this.calculTotal()));
	}

	fileName = 'ExcelSheet.xlsx';
	exportexcel(): void {
		/* table id is passed over here */
		let element = document.getElementById('excel-table');
		const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

		/* generate workbook and add the worksheet */
		var title = 'Situation du ' + this.dateDebut + ' au ' + this.dateFin;
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Situation');

		/* save to file */
		XLSX.writeFile(wb, this.fileName);
	}
	printSituation(): void {
		var params = [
			'height=' + screen.height,
			'width=' + screen.width,
			'fullscreen=yes' // only works in IE, but here for completeness
		].join(',');
		let printContents, popupWin;
		printContents = document.getElementById('print-section').innerHTML;
		popupWin = window.open('', '_blank', params);
		popupWin.document.open();
		popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>
				  table {
					// width: 750px;
					border-collapse: collapse;
					margin: 5px auto;
				}				
				/* Zebra striping */
				tr:nth-of-type(odd) {
					// background: #eee;
					
				}				
				th {
					background: #3498db;
					color: white;
					font-weight: bold;
					text-align: center;
				}				
				td,
				th {
					padding: 5px;
					// border: 1px solid #ccc;
					text-align: left;
					font-size: 12px;
				}
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">${printContents}</body>
			  </html>`);
		popupWin.document.close();
	}
}
