import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Data } from "../../model/stockDepot";
import { DecimalPipe } from '@angular/common';
import {Message,MessageService} from 'primeng/api';
import { AuthenticationService } from '../../authentication.service';
import swal from 'sweetalert';
import * as FileSaver from 'file-saver';
import { Article } from '../../model/article';
import { Depot } from '../../model/depot';
import { Achat } from '../../model/achat';
import * as XLSX from 'xlsx'; 
@Component({
  selector: "app-mouvement-stock-depot",
  templateUrl: "mouvement-stock-depot.component.html",
  styleUrls: ["mouvement-stock-depot.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class MouvementStockDepotComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  Data: Data = {};
  Datas: Data[];
  Depot: Depot = {};
  Variation: Depot = {};
  Depots: Depot[];
  Datass: Data[];
  stockDepotMouvement: Data[];
  DatasDetail: Data[];
  tableVariations: Data[];
  depots: Data[];
  Achat: Achat = {};
  Achats: Achat[];
  selectedData:Achat;
  // depot: Data;
  selectedClient: Data;
  displayDialog: boolean;
  displayDialogMouvement: boolean;
  blockedDocument: boolean;
  afficher: boolean;
  selectedValue: string;
  designation: string;
  depott: string;
  able:boolean;
  Article: Article = {};
  Articles: Article[];
  StockDepot: Data = {};
  StockDepots: Data[];
  filteredArticles: any[];
  stock :number;
  entree :number;
  sortie :number;
  totalAvoir :number;
  totalVariation :number;
  idArticle :number;
  constructor(
    private data: DataService,
    private http: HttpClient,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
    ) {}
  ngOnInit() { 
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
      this.afficher=false;
      this.Variation.observation='';
      this.Variation.stock=0;
    }
    this.article();
    this.depot();
    this.stockDepot();
    this.stockDepotDetail(); 
  }
  stockDepot() {
    // this.Data.action = "SelectRecapDepotMovement";
    this.Data.action = "SelectRecapDepotAllArticle";
    this.Data.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Datas = x,this.Datass=x));
  }
  
  stockDepotDetail() {
    this.Data.action = "SelectMouvement";
    this.Data.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.DatasDetail=x,this.filterMovementStock()));
  }
  filterMovementStock(){

    let recherche = this.DatasDetail.filter(
      (x) => 
      x.idDepotDestination  !=null 
    );
    if (recherche != undefined) {
      // console.log(recherche);
      this.stockDepotMouvement=recherche;
    }
  }

  depot() {
    this.Data.action = "SelectDepot";
    this.Data.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .stockDepot(this.Data)
      .toPromise()
      .then((x: Data[]) => (this.Depots = x));
  }
  article() {
    this.Article.action = "Select";
    this.Article.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .article(this.Article)
      .toPromise()
      .then((x: Article[]) => (this.Articles = x));
  }
  findStockDepot(idArticle,idDepot){
    // alert (idArticle +" | " + idDepot);
    if (idArticle && idDepot){
      let recherche = this.DatasDetail.find(
        (x) => x.idArticle == idArticle 
              && x.idDepot == idDepot 
      );
      if (recherche != undefined) {
        // alert(recherche.stock);
        this.Variation.idDepot = recherche.idDepot;
        this.Variation.idDepot = recherche.idDepot;
        this.Variation.idArticle = recherche.idArticle;
        this.Variation.designation = recherche.designation;
        this.stock = recherche.stock;

        let recherchee = this.DatasDetail.filter(
          (x) => 
          x.idDepotDestination  !=null 
          // &&
          // x.idVariation  ==null
        );
        if (recherchee != undefined) {
          this.tableVariations = recherchee;
          // console.log(recherchee);
        } else {
          // return 'vide';
        }
      } else {
        // alert(this.stock);
        this.stock = 0;
        // return 'vide';
      }   
    }
  }
  addVariation() {
      this.Variation.action = "InsertMouvement";
      this.Variation.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.Variation.quantite = this.Variation.stock ;
      // console.log(this.Variation);
      // alert(this.Variation.dateVariation + " : " + this.Variation.idDepot  + " : " +  this.Variation.quantite)
      if(!this.Variation.dateVariation ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'التاريخ'});
      }
      if(!this.Variation.idDepotDestination ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'المستودع'});
      }
      if(!this.Variation.quantite ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'العدد'});
      }
      if((this.Variation.idDepot == this.Variation.idDepotDestination) ){
        this.messageService.add({severity:'warn', summary: 'انتباه', detail: 'نفس المستودع'});
      }
      if(this.Variation.dateVariation && this.Variation.idDepotDestination && this.Variation.quantite && (this.Variation.idDepot!=this.Variation.idDepotDestination)){
        this.blockedDocument=true;
        this.data
          .variationStockDepot(this.Variation)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.Variation = {};
              this.stockDepot();
              this.stockDepotDetail(); 
              this.displayDialog = false;
              this.Variation.observation='';
              this.Variation.stock=0;
              this.blockedDocument=false;
            } else {
              alert(res.message);
              this.displayDialog = false;
              this.blockedDocument=false;
            }
          });      
      }  
  }

  deleteVariation() {
    if (confirm("êtes vous sure?" + this.Variation.id)) {
      this.blockedDocument=true;
      this.Variation.action = "Delete";
      this.Variation.DBase = this.AuthenticationService.currentUserValue.DBase;
      // console.log(this.Variation);
      this.data
        .variationStockDepot(this.Variation)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.Variation = {};
            this.stockDepot();
            this.stockDepotDetail(); 
            this.Variation.observation='';
            this.Variation.stock=0;
            this.blockedDocument=false;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  testStockDepot(){
    if(this.Variation.idDepot == this.Variation.idDepotDestination){
      alert("Vous avez entrer le même depot !!!")
    }
  }
  showDialogToMouvment(){
    this.displayDialogMouvement = true;
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.Variation = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.displayDialog = true;
    this.Variation.dateVariation = new Date().toISOString().split("T")[0];
    this.Variation.idDepot = event.data.idDepot;
    this.Variation.depot = event.data.depot;
    this.Variation.idArticle = event.data.idArticle;
    this.idArticle = event.data.idArticle;
    this.Variation.designation = event.data.designation;
    this.Variation.entree = event.data.entree;
    this.Variation.sortie = event.data.sortie;

    this.stock = event.data.stock;
    this.entree = event.data.entree;
    this.sortie = event.data.sortie;
    
    // console.log(event.data);
    // console.log(this.DatasDetail);
    let recherche = this.DatasDetail.filter(
      (x) =>    
          x.idArticle            == event.data.idArticle &&
          x.idDepot              == event.data.idDepot &&
          x.idDepotDestination  !=  null  && 
          x.idVariation          == 0   
    );
    if (recherche != undefined) {
      this.tableVariations = recherche;
      // console.log(recherche);
    } else {
      // return 'vide';
    }
    this.Variation.observation='';
    this.Variation.stock=0;
    // alert(this.tableVariations);
  }
  fileName= 'ExcelSheet.xlsx';  


  exportExcel(dd){
    // console.log(dd.filteredValue);

      import("xlsx").then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(dd.filteredValue);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, "Situation");
      });
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
  onRowSelectVariation(event) {
    // alert(event.data.dateVariation);
    // console.log(event.data);
    this.newDonnee = false;
    this.Variation = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: Data): Data {
    let Variation = {};
    for (let prop in c) {
      Variation[prop] = c[prop];
    }
    return Variation;
  }
  close() {
    this.displayDialog = false;
    this.Variation = {};
  }
  closeMovement(){
    this.displayDialogMouvement= false;
  }
}
