import { Component, OnInit } from "@angular/core";
import { DataService } from "../../data.service";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Type } from "../../model/type";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-types",
  templateUrl: "types.component.html",
  styleUrls: ["types.component.scss"],
  providers: [MessageService],
})
export class TypesComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  Type: Type = {};
  Types: Type[];
  selectedType: Type;
  displayDialog: boolean;
  selectedOption: string;
  file?: string;
  selectedFile = null;
  able: boolean =false;
  admin: boolean =false;
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
  ngOnInit() {  
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Admin'){
      this.admin=true;
    }
    this.type();
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {

    // alert(this.Type.type);
    this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.Type.chemin = this.myUrl + "/API/API/";
    console.log(this.Type);
    this.data
      .posteFile(
        this.Type.id,
        this.Type.type,
        this.Type.DBase,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: data.message});
        this.type();
        this.displayDialog = false;
      });
  }
  type() {
    this.Type.action = "Select";
    this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .type(this.Type)
      .toPromise()
      .then((x: Type[]) => (this.Types = x));
  }
  save() {
    if (this.Type.classement == null) {
      alert("classement est vide - رقم السلسل فارغ");
    } else if (this.Type.type == null) {
      alert("famille est vide - النوع فارغ");
    } else {
      if (this.Type.id) {
        this.Type.action = "Update";
        this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .type(this.Type)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.type();
            } else {
              console.log(res);
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.Type.action = "Insert";
        this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .type(this.Type)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.type();
            } else {
              alert(res.message);
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete() {
    if (confirm("êtes vous sure?")) {
      this.Type.action = "Delete";
      this.Type.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .type(this.Type)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Supression', detail: res.message});
            this.displayDialog = false;
            this.type();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.Type = {};
    this.file="";
  }
  newDonnee: boolean;
  showDialogToAdd() {
    this.newDonnee = true;
    this.file="";
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Type = {};
    this.displayDialog = true;
  }
  onRowSelect(event) {
    console.log(event);
    this.file="";
    this.newDonnee = false;
    if (event.data.images == null) {
      var leet = this.myUrl + "/API/API/uploads/default.png";
    }else{
      var leet = this.myUrl + "/API/API/uploads/images/" + event.data.images;     
    }
    this.imageUrl = leet;
    // alert(leet);
    this.Type = this.cloneDonnee(event.data);
    this.displayDialog = true;
  }
  cloneDonnee(c: Type): Type {
    let Type = {};
    for (let prop in c) {
      Type[prop] = c[prop];
    }
    return Type;
  }
}
