import { Component, OnInit,Input } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { DataService } from "../../data.service";
import { AuthenticationService } from "../../authentication.service";
import { environment } from "../../../environments/environment";
import * as XLSX from "xlsx";
import { Vente } from "../../model/vente";
import { Client } from "../../model/client";
import { Paiement } from "../../model/paiement";
import {Message,MessageService} from 'primeng/api';
import { Avoir } from "../../model/avoir";
import { Achat } from "src/app/model/achat";
import { ModePaiement } from "src/app/model/modePaiement";


@Component({
  selector: "app-situation-paiement-client.component",
  templateUrl: "situation-paiement-client.component.html",
  styleUrls: ["situation-paiement-client.component.scss"],
  providers: [DecimalPipe,MessageService],
})
export class SituationPaiementClientComponent implements OnInit {  
  // @Input() francais: boolean;
  // @Input() langueDirection: string;
  // @Input() langueLbl: string ;  
  msgs1: Message[];    
  msgs2: Message[];
  idPaiement: number;
  Paiement: Paiement = {};
  Paiements: Paiement[];
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  Avoir: Avoir = {};
  Avoirs: Avoir[];
  Achat: Achat = {};
  Achats: Achat[];
  tableRecapAchats: Achat[];
  tableDetailAvoirs: Avoir[];
  tableDetailAvoirAchats: Avoir[];
  tableDetailAchats: Vente[];
  tableRecapAvoirs: Avoir[];
  tableRecapeAvoirs: Avoir[];
  tableRecapAvoirAchat: Avoir[];
  tableRecapAvoirAchats: Avoir[];
  tableRecapeAchats: Vente[];
  // recherchePaiement: Paiement = {};
  tableRecapPaiements: Paiement [];
  tableRecapSoldeInitial: Paiement [];
  tableClientPaiements: Vente [];
  tableClientPaiementss: Vente [];
  tableDetailsVentesRequetteBetwin: Vente[];
  Vente: Vente = {};
  Ventes: Vente[];
  selectedVente: Vente;
  tableVentes: Vente[];
  tableDetailsVentes: Vente[];
  tableDetailVentes: Vente[];
  tableCommandeDetail: Vente[];
  tableDetailsVentesRequette: Vente[];
  tableDetailPaiements: Paiement[];
  Cheques: Paiement[];
  selectedCommandeDetail: Vente;
  tableCommandeVente: Vente = {};
  tableCommandeVentes: any = [];
  requetteCommandeVente: Vente = {};
  requetteCommandeVentes: any = [];
  Client: Client = {};
  Clients: Client[];
  blockedDocument: boolean;
  displayDialog: boolean;
  displayDialogHistorique: boolean;
  displayDialogDetailBon: boolean;
  displayDialogDetailAchat: boolean;
  displayDialogDetailAvoir: boolean;
  displayDialogDetailAvoirAchat: boolean;
  displaycheque: boolean;
  francais: boolean =false;
  langueDirection: string ="ltr";
  langueLbl: string ="العربية";
  showButon: boolean;
  showButonSauvgarder: boolean;
  designation: string;
  dateSituationDebut: string;
  dateSituationFin: string;
  code: string;
  nom: string;
  nomAr: string;
  nomFilter: string;
  nomArFilter: string;
  nomm: string;
  dateD: any ;
  totalMontant: number;
  totaldetailBon: number;
  totalQte: number;
  total: number;
  soldeInitial: number;
  montant: number;
  avance: number;
  achat: number;
  avoir: number;
  avoirAchat: number;
  totalCheque: number;
  reste: number;
  idClient: number;
  index: string;
  commandeDetail: string;
  dateDebut: any;
  dateFin: any;
  nomClient:string;
  able: boolean; 
  AdminAble: boolean; 
  myUrl: string = `${environment.urlApi}`;
  constructor(
    private data: DataService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // this.fournisseur();
    // this.client();
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    if(this.AuthenticationService.currentUserValue.groupe=='Admin' ){
      this.AdminAble=true;
    }
    if(this.AuthenticationService.currentUserValue.francais){
      this.francais=false;
      this.langueDirection="ltr"
      this.langueLbl="العربية"
    }else{
      this.francais=true;
      this.langueDirection="rtl"
      this.langueLbl="الفرنسية"
    }
    this.vente();
    this.venteDetail();
    this.venteRecap();
    this.paiementDetail();
    this.modePaiement();
    // this.paiementDetailClient();
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 2);
    var lastDay = new Date(y, m + 1, 0);
    // alert(firstDay);
    this.Paiement.dateReglement     = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande      = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque        = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance      = new Date().toISOString().split("T")[0];
    this.dateSituationDebut         = firstDay.toISOString().split("T")[0];
    this.dateSituationFin           = new Date().toISOString().split("T")[0];

  }
  // client() {
  //   this.Client.action = "Select";
  //   this.data
  //     .client(this.Client)
  //     .toPromise()
  //     .then((x: Client[]) => (this.Clients = x));
  // }
  venteDetail() {
    this.Vente.action = "SelectDetail";
    this.Vente.depot  = "MAGASIN";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .vente(this.Vente)
      .toPromise()
      .then((x: Vente[]) => (this.tableDetailVentes = x));
  }
  // fournisseur() {
  //   this.Client.action = "Select";
  //   this.data
  //     .fournisseur(this.Client)
  //     .toPromise()
  //     .then((x: Client[]) => (this.Clients = x));
  // }

  vente() {
    this.Vente.action = "RecapByClient";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapClient(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (this.Ventes = x, this.tableVentes = x, this.paiementRecap())
      );
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.soldesInitial()));
  }
  soldesInitial() {
    this.Paiement.action = "soldeInitial";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapSoldeInitial = x,
        // console.log(x),
        this.avoirRecap()));
  }
  avoirRecap() {
    this.Avoir.action = "SelectRecapByIdClient";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoir(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirs = x,
        this.avoirAchatRecap()));
  }
  avoirAchatRecap() {
    this.Avoir.action = "SelectRecapByIdFournisseurClient";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirAchats = x,
        // console.log(x),
        this.achatRecap()));
  }
  achatRecap() {
    this.Achat.action = "SelectRecapByIdClient";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapAchats = x,
        // console.log(x),
        this.tablePaiement()));
  }
  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
      if(this.tableVentes.length>0){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avance = 0;
            this.tableVentes[index].reste = this.tableVentes[index].total;  
          }else{
            // if(updateItem.modePaiement=='Solde'){
            //   this.tableVentes[index].soldeInitial = -updateItem.montant;
            //   this.tableVentes[index].avance = 0;
            //   this.tableVentes[index].reste = this.tableVentes[index].total-updateItem.montant;
            // }else{
              this.tableVentes[index].avance = updateItem.montant;
              this.tableVentes[index].reste = this.tableVentes[index].total-updateItem.montant;
            // }
          }
        });        
      }

    } 
    this.tableSoldeInitial();
}
  tableSoldeInitial(){
    if (this.tableRecapSoldeInitial.length!=undefined){
      if(this.tableVentes.length>0){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapSoldeInitial.find(item=>item.idClient==row.idClient);
          // if(row.idClient==1) {
          //   console.log(updateItem);
          // }
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].soldeInitial = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].soldeInitial = updateItem.montant;
          this.tableVentes[index].reste += -updateItem.montant;      
          }
        });         
      }

    }
    this.tableAvoir();
}
  tableAvoir(){
    if (this.tableRecapAvoirs.length!=undefined){
      if(this.tableVentes.length>0){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirs.find(item=>item.idClient==row.idClient);
          if (updateItem===undefined){
            this.tableVentes[index].avoir = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].avoir = updateItem.montant;
          this.tableVentes[index].reste += -updateItem.montant;      
          }
        });         
      }

    }
    this.tableAvoirAchat();
}
  tableAvoirAchat(){
    if (this.tableRecapAvoirAchats){
      // console.log(this.tableRecapAvoirAchats);
      if(this.tableVentes.length>0){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avoirAchat = 0;
            
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].avoirAchat = updateItem.montant;
          this.tableVentes[index].reste += updateItem.montant;      
          }
        });         
      }

    }else{
      this.tableVentes.forEach((row ,index )=>{
        this.tableVentes[index].avoirAchat = 0;
      });
    }
    this.tableAchat();
}
  tableAchat(){
    if (this.tableRecapAchats.length!=undefined){
      if(this.tableVentes.length>0){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].achat = 0;
          }else{
          this.tableVentes[index].achat = updateItem.total;
          this.tableVentes[index].reste += -updateItem.total;      
          }
        });         
      }

    }
     this.filter();
}
filterCommande(startDate,endDate){
  let recherche = this.tableDetailsVentesRequette.filter((x) => x.dateCommande >= startDate && x.dateCommande <= endDate+1 );
  this.tableDetailsVentesRequetteBetwin = recherche;
  // console.log(recherche);
}
  filter() {
    if(!this.nomFilter){this.nomFilter="";}
    if(!this.nomArFilter){this.nomArFilter="";}
    if(!this.code){this.code="";}
    if(this.Ventes.length>0){
      let recherche = this.Ventes.filter((x) => 
        x.nom.toString().toLocaleLowerCase().includes(this.nomFilter.toString().toLocaleLowerCase()) &&
        x.nomAr.toString().toLocaleLowerCase().includes(this.nomArFilter.toString().toLocaleLowerCase()) &&
        x.code.toString().toLocaleLowerCase().includes(this.code.toString().toLocaleLowerCase())
      );
      if (recherche){
        // console.log(recherche);
        this.tableVentes = recherche;
        this.totalCommande();    
      }      
    }

  }

  totalCommande() {
    // console.log(this.tableVentes);
    this.soldeInitial = 0;
    this.total = 0;
    this.avance = 0;
    this.avoir = 0;
    this.totalCheque = 0;
    this.reste = 0;
    this.soldeInitial = this.tableVentes.map((t) => (t.soldeInitial) * 1).reduce((a, value) => a + value, 0);
    this.total        = this.tableVentes.map((t) => (t.total) * 1).reduce((a, value) => a + value, 0);
    this.avance       = this.tableVentes.map((t) => (t.avance) * 1).reduce((a, value) => a + value, 0);
    this.achat        = this.tableVentes.map((t) => (t.achat) * 1).reduce((a, value) => a + value, 0);
    this.avoir        = this.tableVentes.map((t) => (t.avoir) * 1).reduce((a, value) => a + value, 0);
    this.avoirAchat   = this.tableVentes.map((t) => (t.avoirAchat) * 1).reduce((a, value) => a + value, 0);
    this.reste        = this.tableVentes.map((t) => (t.reste) * 1).reduce((a, value) => a + value, 0);
    this.totalCheque  = this.Cheques.map((t) => (t.montant) * 1).reduce((a, value) => a + value, 0);
  } 

  paiementDetail() {
    this.Paiement.action = "Select";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        // console.log(x),
        this.Cheques =x.filter((x) => x.modePaiement != 'Espèce' && x.modePaiement!='Solde' && x.modePaiement!='Remise'),
        // console.log(this.Cheques),
        this.filter()));
  }

  venteRecap() {
    this.Vente.action = "SelectDetailPaiment";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .vente(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          (this.tableDetailsVentes = x),
          this.paiementDetailClient()
        )
      );
  }

  paiementDetailClient() {
    this.Paiement.action = "Detail";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Vente[]) => (
        this.tableClientPaiements = x,
        this.tableClientPaiementss = x,
        this.avoirRecape()
      ));
  }

  avoirRecape() {
    // alert('eee');
    this.Avoir.action = "Select";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirClient(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapeAvoirs = x,
        this.avoirAchatRecape()
      ));
  }
  avoirAchatRecape() {
    // alert('eee');
    this.Avoir.action = "SelectAvoir";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirAchat = x,
        // console.log(x),
        this.achatRecape()
      ));
  }

  achatRecape() {
    // alert('eee');
    this.Achat.action = "SelectFournisseurClient";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .achat(this.Achat)
      .toPromise()
      .then((x: Vente[]) => (
        this.tableRecapeAchats = x,
        // console.log(x),
        this.tablePaiementDetail()
      ));
  }

  cloturePaiement(){
    var requete='INSERT INTO situationpaiementclient (idCommande,idClient,dateReglement,montant,modePaiement,reference,regle,revendeur) VALUES';
    let recherche= this.tableVentes.filter((x) => x.reste != 0);
    recherche.forEach((row ,index )=>{
      requete+="(0,"+row.idClient+", '2022-01-01', -"+row.reste+",'Solde', '', 'o', 'n'),";
    });
    requete+=";";
    requete=requete.replace(",;", ";");
    // let total= recherche.map((t) => (t.total) * 1).reduce((a, value) => a + value, 0);
    // let avance= recherche.map((t) => (t.avance) * 1).reduce((a, value) => a + value, 0);
    // let achat= recherche.map((t) => (t.achat) * 1).reduce((a, value) => a + value, 0);
    // let avoir= recherche.map((t) => (t.avoir) * 1).reduce((a, value) => a + value, 0);
    // let avoirAchat= recherche.map((t) => (t.avoirAchat) * 1).reduce((a, value) => a + value, 0);
    // let reste= recherche.map((t) => (t.reste) * 1).reduce((a, value) => a + value, 0);
    // console.log(requete);
    // console.log(this.tableVentes);
    // console.log(' total : '+total +' avance : '+avance+' achat : '+achat + ' avoir : '+avoir+' avoirAchat : '+avoirAchat+'reste : '+reste);
  }
  tablePaiementDetail(){
    if (this.tableDetailsVentes){
        this.tableDetailsVentesRequette = this.tableDetailsVentes;

        if (this.tableClientPaiements){
          console.log(this.tableClientPaiements);
          this.tableClientPaiements.forEach((row ,index )=>{
            this.tableDetailsVentesRequette.push(row);
          })
        }
        if (this.tableRecapeAvoirs){
          // console.log(this.tableRecapeAvoirs);
          this.tableRecapeAvoirs.forEach((row ,index )=>{
            this.tableDetailsVentesRequette.push(row);
          })           
        }
        if (this.tableRecapAvoirAchat){
          // console.log(this.tableRecapAvoirAchat);
          this.tableRecapAvoirAchat.forEach((row ,index )=>{
            this.tableDetailsVentesRequette.push(row);
          })
        }
        if (this.tableRecapeAchats){
          this.tableRecapeAchats.forEach((row ,index )=>{
          this.tableDetailsVentesRequette.push(row);
          })
        }
        this.tableDetailsVentesRequette.sort((b, a) => new Date(b.dateCommande).getTime() - new Date(a.dateCommande).getTime());
        // this.filterPaiement();
    }
}
filterPaiement(){
  if(this.tableClientPaiements){
    let rechercheP = this.tableClientPaiements.filter((x) => x.idClient==this.idClient ); 
    this.tableClientPaiementss = rechercheP;    
  }
  this.avoirDetail();
}

avoirDetail() {
  // alert('eee');
  this.Avoir.action = "Detail";
  this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .avoirClient(this.Avoir)
    .toPromise()
    .then((x: Avoir[]) => (
      this.tableDetailAvoirs = x,
      this.avoirAchatDetail()
    ));
}
avoirAchatDetail() {
  // alert('eee');
  this.Avoir.action = "SelectDetailAchat";
  this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .avoirAchat(this.Avoir)
    .toPromise()
    .then((x: Avoir[]) => (
      this.tableDetailAvoirAchats = x,
      // console.log(x),
      this.achatDetail()
    ));
}
achatDetail() {
  // alert('eee');
  this.Achat.action = "SelectDetail";
  this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
  this.data
    .achat(this.Achat)
    .toPromise()
    .then((x: Vente[]) => (
      this.tableDetailAchats = x
    ));
}

changeLangue(){
  // alert(this.francais);
  
  this.nomFilter="";
  this.nomArFilter="";
  this.code="";
  this.filter();
  if(this.francais==true){
    this.francais=false;
    this.langueDirection="ltr"
    this.langueLbl="العربية"
  }else{
    this.francais=true;
    this.langueDirection="rtl"
    this.langueLbl="الفرنسية"
  }
  
} 


filterCheque(data){
  // console.log(data);
  this.Cheques =this.tableDetailPaiements.filter((x) => x.modePaiement != 'Espèce' && x.nom.toLocaleLowerCase().includes(data.toLocaleLowerCase()));
  this.totalCommande();
}


  transformDecimal(num) {
    return this._decimalPipe.transform(num, "1.2-2");
  }
  showDialogCheque(){
    this.displaycheque=true;
  }
  espece(){
   
    if (this.Paiement.modePaiement=='espece'){
      this.Paiement.reference=""; 
      this.Paiement.dateEcheance = new Date()
      .toISOString()
      .split("T")[0];
    }
  }
   save() {
    
    if (this.Paiement.id) {
      // alert(this.Paiement.id);
      this.Paiement.action = "Update";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      // console.log(this.Paiement);
      this.data
        .paiementClient(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogDetailAchat = false;
            this.displayDialogDetailAvoir = false;
            this.displayDialogDetailAvoirAchat = false;
            this.displayDialogHistorique = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            this.filter();
          } else {
            alert("Nonnnn");
          }
        });
    } else {
      // alert('add');
      if (!this.idClient ){
        alert("Vous avez oublier le CLient !")
      }else{
        this.Paiement.action = "Insert";
        this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
        this.Paiement.idClient = this.idClient;
        this.data
          .paiementClient(this.Paiement)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.displayDialogDetailBon = false;
              this.displayDialogDetailAchat = false;
              this.displayDialogDetailAvoir = false;
              this.displayDialogDetailAvoirAchat = false;
              this.displayDialogHistorique = false;
              this.vente();
              this.venteDetail();
              this.venteRecap();
              this.paiementDetail();
              this.filter();
            } else {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
      }
    }
  }
  delete(id) {
      // this.messageService.clear();
      // this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'êtes vous sure de supprimer?', detail:'Confirm to proceed'});
    if (confirm("êtes vous sure?")) {
      this.Paiement.action = "Delete";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = id;
      this.data
        .paiementClient(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Warning', detail: res.message});
            
            // this.paiementRecap();
            // this.venteRecap();
            // this.paiementDetailClient();
            this.displayDialog = false;
            this.displayDialogDetailBon = false;
            this.displayDialogDetailAchat = false;
            this.displayDialogDetailAvoir = false;
            this.displayDialogDetailAvoirAchat = false;
            this.displayDialogHistorique = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            alert(this.nom);
            this.filter();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  printSituation(): void {
    let dateSituationDebut = new Date(this.dateSituationDebut).toISOString().split('T')[0];
    var params = [
      "height=" + screen.height,
      "width=" + screen.width,
      "fullscreen=yes", // only works in IE, but here for completeness
    ].join(",");
    let printContents, popupWin;
    printContents = document.getElementById("print-section-client").innerHTML;
    popupWin = window.open("", "_blank", params);
    popupWin.document.open();
    popupWin.document.write(`
			  <html>
				<head>
				  <title>Print tab</title>
				  <style>          
            .fontArab {
              font-family: droidkufi, lato, sans-serif !important;
              font-weight: bold;
            }
              table {
              // width: 750px;
              border-collapse: collapse;
              margin: 5px auto;
              border:1px solid;
            }
            td, th {
              border:1px solid;
              font-family: droidkufi, lato, sans-serif !important;
            }
            tr:nth-child(odd) {background: none repeat scroll 0 0 #F2F5F9;}
            tr:nth-child(even) {background-color: #FFFFFF;}
            /* Zebra striping */
            tr:nth-of-type(odd) {
              // background: #eee;					
            }				
            th {
              background: #3498db;
              color: white;
              font-weight: bold;
              text-align: center;
            }				
            td,
            th {
              padding: 5px;
              border: 1px solid #ccc;
              text-align: left;
              font-size: 12px;
            }
				  </style>
				</head>
			<body onload="window.print();window.close()" style="text-align:center;">`+`${printContents}</body>
			  </html>`);
    popupWin.document.close();
  }

  exportExcelClient(): void {
    let fileName = "Situation des paiements du Client " + this.nom +  ".xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-client");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation du Client : "+ this.nom ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        this.nom
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  exportExcelClients(): void {
    let fileName = "Situation des paiements.xlsx";  
      /* table id is passed over here */
      let element = document.getElementById("excel-table-clients");
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      var title ="Situation " ;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        'Situation Paiment'
      );
  
      /* save to file */
      XLSX.writeFile(wb, fileName);
    }
  cloture(idPaiement,mode) {
    // alert(idPaiement+" | "+mode);

      this.blockedDocument=true;
      this.Paiement.action = "UpdateRegle";
      this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
      this.Paiement.id = idPaiement;
      this.Paiement.regle = mode;
      this.data
        .paiementClient(this.Paiement)
        .toPromise()
        .then((res: { message: string }) => {
          if (res.message == "Opération de modification réuissie") {
            // alert(res.message);
            this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
            this.blockedDocument=false;
            // this.displaycheque = false;
            this.vente();
            this.venteDetail();
            this.venteRecap();
            this.paiementDetail();
            this.filterCheque(this.nomClient);
          } else {
            alert(res.message);
          }
        });
  }
  newDonnee: boolean;
  showDialog() {
    this.filterPaiement();
    this.displayDialog = true;
    this.newDonnee = true;
    this.Vente = {};
  }
  showDialogToAdd() {
    this.filterPaiement();
    this.showButon = false;
    this.showButonSauvgarder = false;
    // this.newDonnee = true;
    this.Paiement={};
    this.Paiement.modePaiement='espece';
    this.Paiement.regle="o";
    this.Paiement.revendeur="n";
    this.Vente = {};
    this.tableCommandeVente = {};
    this.tableCommandeVentes = [];
    this.Vente.dateCommandee = new Date().toISOString().split("T")[0];
    this.Paiement.dateCommande = new Date().toISOString().split("T")[0];
    this.Paiement.dateReglement = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque = new Date().toISOString().split("T")[0];
    this.Paiement.idClient=this.idClient;
    this.Paiement.idModePaiement=1;
    this.Paiement.modePaiement="Espèce";

    this.displayDialog = true;
  }
  addPaiement(data) {
    // console.log(data)
    // if (this.tableCommandeVente.idPaiement) {
      this.tableCommandeVentes.push(data);

      // var index = this.tableCommandeVentes.findIndex(
      //   (x) => x.idPaiement == this.idPaiement
      // );

      this.showButon = false;
      this.totalQantite();
      // this.prepareCommandeDetail();
      this.tableCommandeVente = {};
      // alert("2");
    // } else {
    //   alert("Entrer un Paiement !");
    // }

    // var index = this.tableCommandeVentes.findIndex((x) => x.index == index);
  }
  updatePaiement(data) {
    this.tableCommandeVentes[this.index].idClient = data.idClient;
    this.tableCommandeVentes[this.index].dateReglement = data.dateCommande;
    this.tableCommandeVentes[this.index].dateCheque = data.dateCheque;
    this.tableCommandeVentes[this.index].montant = data.montant;
    this.tableCommandeVentes[this.index].modePaiement = data.modePaiement;
    this.tableCommandeVentes[this.index].reference = data.reference;
    this.tableCommandeVentes[this.index].regle = data.regle;
    // this.idClient = this.tableCommandeVente.idClient;
    // this.tableCommandeVente = {};
    // this.tableCommandeVente.idClient = this.idClient;
    // this.tableCommandeVente.dateCommande = new Date()
    //   .toISOString()
    //   .split("T")[0];
    this.showButon = false;
    this.totalQantite();
    // this.prepareCommandeDetail();
    //  this.tableCommandeVente = {};
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x));
  }

  findModePaiement(idModePaiement) {
    let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
    this.Paiement.modePaiement = rechercheModePaiement.modePaiement;
  }

  findPaiement(idPaiement) {
    
    let recherche = this.Paiements.find((x) => x.id == idPaiement);
    this.designation = recherche.designation;
    this.idPaiement = idPaiement;
    // alert(this.idPaiement);
    // if (recherche != undefined) {
    // 	return recherche.designation;
    // } else {
    // 	return '0';
    // }
  }
  totalQantite() {
    let totalMontant = 0;
    let totalQte = 0;
    for (let data of this.tableCommandeVentes) {
      totalMontant += data.montant*1;
    }
    this.totalMontant = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
    this.filterCommande(this.dateSituationDebut,this.dateSituationFin);
  }
  totalBonDetail(){
    let totalMontant = 0;
    for (let data of this.tableCommandeDetail) {
      totalMontant += data.total;
    }
    this.totaldetailBon = totalMontant;
    // return this._decimalPipe.transform(total, '1.2-2');
    // return total.toFixed(2);
  }

  deleteCommande(idPaiement) {
    // alert(idPaiement);
    var index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == idPaiement
    );
    // alert(index);
    this.tableCommandeVentes.splice(index, 1);
    this.totalQantite();
    // this.prepareCommandeDetail();
    // this.tableCommandeVente = {};
    // console.log(this.vente);
    // var index = this.vente.findIndex((x) => x.idPaiement == idPaiement);
  }
  prepareCommandeDetail() {
    this.commandeDetail = "";
    for (let data of this.tableCommandeVentes) {
      this.commandeDetail +="('idVente','" +data.idPaiement +"','" +data.quantite +"','" +data.prixVente +"','" +
        data.prixVenteDetail +"','" +data.prixVenteGros +"','" +data.prixVente +"'),";
    }
    this.commandeDetail += "$";
    this.commandeDetail = this.commandeDetail.replace(",$", ";");
    this.initialiser();
    // alert(this.tableCommandeVente.idClient);
  }

  onRowSelect(event) {
    // alert(event.data.idClient)
    this.displayDialogHistorique = true;

    // console.log();
    this.idClient = event.data.idClient;
    this.nom = event.data.nom;
    this.nomAr = event.data.nomAr;
    this.montant = event.data.montant - event.data.remise;
    this.initialiser();
    this.filterPaiement();
 
    let recherche = this.tableDetailsVentes.filter((x) => x.idClient == event.data.idClient);
    this.tableDetailsVentesRequette = recherche;
    // console.log(this.tableDetailsVentesRequette);
    let cumul=0;
    if (this.tableDetailsVentesRequette.length!=undefined){
      this.tableDetailsVentesRequette.forEach((row ,index )=>{
        // console.log(row.mode+'z');
        if(row.mode=='Avoir' || row.mode=='Avance'|| row.mode=='Achat'){
          if (row.regle=='o' || row.mode=='Avoir'|| row.mode=='Achat'){
              cumul += -row.avance*1;
          }else{
            cumul += 0;
          }          
        }else if(row.mode=='Bon' ){
          cumul += row.montant*1 - row.remise*1;
        } else if(row.mode=='AvoirAchat'){
           cumul += row.montant*1 ;
        }
        this.tableDetailsVentesRequette[index].cumul = cumul;
      })
    }
    this.totalQantite();
    this.filterCommande(this.dateSituationDebut,this.dateSituationFin);
    
  }
  cloneDonnee(c: Vente): Vente {
    let Vente = {};
    for (let prop in c) {
      Vente[prop] = c[prop];
    }
    return Vente;
  }

  onRowSelectDetailBon(event) {
    // console.log(event.data);
    if (event.data.mode=='Bon'){
      this.displayDialogDetailBon = true;
      this.Vente.remise = event.data.remise;
      let recherche = this.tableDetailVentes.filter((x) => x.id == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      this.totalBonDetail();
    }else if (event.data.mode=='Achat'){
      this.displayDialogDetailAchat = true;
      this.Vente.remise = event.data.remise;
      let recherche = this.tableDetailAchats.filter((x) => x.id == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      this.totalBonDetail();
    }else if (event.data.mode=='Avoir'){
      this.displayDialogDetailAvoir = true;
      let recherche = this.tableDetailAvoirs.filter((x) => x.idCommande == event.data.idCommande);
      this.tableCommandeDetail= recherche;
      this.totalBonDetail();
    }else if (event.data.mode=='AvoirAchat'){
      this.displayDialogDetailAvoirAchat = true;
      // console.log( this.tableDetailAvoirAchats);
      let recherche = this.tableDetailAvoirAchats.filter((x) => x.idClient == event.data.idClient);
      this.tableCommandeDetail= recherche;
      this.totalBonDetail();
    }else if(event.data.mode=='Avance' && event.data.modePaiement!='Solde'){
      this.displayDialog=true;
      let rechercheP = this.tableClientPaiements.filter((x) => 
        x.idClient==this.idClient  && x.id==event.data.id      
      ); 
      this.tableClientPaiementss = rechercheP;
      this.onRowSelectCommandeDetail(event);

    }
  }
  onRowSelectCommandeDetail(event) {
    this.showButon = true;
    this.showButonSauvgarder = true;
    this.index = this.tableCommandeVentes.findIndex(
      (x) => x.idPaiement == event.data.idPaiement
    );
    // alert( event.data.idClient);
    this.Paiement.idClient = event.data.idClient;
    this.newDonnee = false;
    this.Paiement = this.cloneDonneeCommandeDetail(event.data);
    this.displayDialog = true;
  }
  cloneDonneeCommandeDetail(c: Paiement): Paiement {
    let tableCommandeVente = {};
    for (let prop in c) {
      tableCommandeVente[prop] = c[prop];
    }
    return tableCommandeVente;
  }
  close() {
    this.displayDialog = false;
    this.tableCommandeVentes = [];
    this.tableCommandeVente = {};
    this.requetteCommandeVentes = [];
    this.requetteCommandeVente = {};
    this.tableCommandeVente.dateReglement = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    this.tableCommandeVente.dateCheque = new Date()
      .toISOString()
      .split("T")[0];
    this.totalQantite();
    // this.prepareCommandeDetail();
  }
  initialiser() {
    this.Paiement = {};
    // this.tableDetailsVentesRequette = [];
    this.Paiement.modePaiement='Espèce';
    this.Paiement.regle="o";
    this.Paiement.revendeur ="n";
    // this.Paiement.modePaiement="";tableDetailsVentesRequette
    // this.tableCommandeVente = {};
    this.Paiement.dateCommande = new Date().toISOString().split("T")[0];
    this.Paiement.dateEcheance = new Date().toISOString().split("T")[0];
    this.Paiement.dateReglement = new Date().toISOString().split("T")[0];
    this.Paiement.dateCheque = new Date().toISOString().split("T")[0];
    this.Paiement.idModePaiement = 1;
    this.requetteCommandeVentes = this.tableCommandeVentes;
    // this.tableCommandeVente.idPaiement = null;
    // this.tableCommandeVente.quantite = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVente = "";
    // this.tableCommandeVente.prixVenteDetail = "";
    // this.tableCommandeVente.prixVenteGros = "";
    this.showButon = false;
  }
  closeCheque(){
    this.displaycheque=false;
  }
  closeHistorique(){
    this.displayDialogHistorique=false;
  }
  closeDetailBon(){
    this.displayDialogDetailBon=false;
  }
  closeDetailAchat(){
    this.displayDialogDetailAchat=false;
  }
  closeDetailAvoir(){
    this.displayDialogDetailAvoir=false;
  }
  closeDetailAvoirAchat(){
    this.displayDialogDetailAvoirAchat=false;
  }
}