import { Component, OnInit } from "@angular/core";
import { DataService } from "../data.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ModePaiement } from "../model/modePaiement";
import {Message,MessageService} from 'primeng/api';
import { Router } from '@angular/router';
import { AuthenticationService } from "src/app/authentication.service";
import { DataBase } from "../model/dataBase";
import { Data } from "../model/stockDepot";
import { Vente } from "../model/vente";
import { Paiement } from "../model/paiement";
import { Avoir } from "../model/avoir";
import { Achat } from "../model/achat";
import { DecimalPipe } from "@angular/common";
import { TreeNode } from 'primeng/api';

// import { ConfirmDialogModule } from "primeng/confirmdialog";
// import { ConfirmationService } from "primeng/api";
@Component({
  selector: "app-cloture",
  templateUrl: "cloture.component.html",
  styleUrls: ["cloture.component.scss"],
  providers: [MessageService],
})
export class ClotureComponent implements OnInit {
  myUrl: string = `${environment.urlApi}`;
  imageUrl: string = this.myUrl + "/API/API/uploads/default.png";
  fileToUpload: File = null;
  ModePaiement: ModePaiement = {};
  ModePaiements: ModePaiement[];
  selectedType: ModePaiement;
  displayDialog: boolean;
  displayDialogSte: boolean;
  displayDialogMigration: boolean=false;
  selectedOption: string;
  selectedFile = null;
	blockedDocument: boolean = false;

  Donnee        : DataBase = {};
  Donnees       : DataBase[];
  files!        : TreeNode[];
  data1         : DataBase[];
  data2         : DataBase[];
  Migration     : DataBase[];  
  selectedTable : DataBase;
  dataTable     : string;
  able          : boolean =false;

  //DEPOTS
  DataTables    : Data[];  
  Datass        : Data[];  
  selectedData  : Data;
  stockReel     : number;
  designation   : string;
  depott        : string;

  //stes
  stes          : DataBase[]; 
  ste           : DataBase = {};
  //VENTE
  tableVentes   : Vente[];
  Ventes        : Vente[];
  Vente         : Vente = {};
  selectedVente : Vente;
  nom           : string;
  nomAr         : string;
  codeClient    : string;
  requeteStock  : string;
  requeteClient  : string;
  requeteFournisseur  : string;
  idModePaiement: number;
  Paiement      : Paiement = {};
  Paiements     : Paiement[];
  tableRecapPaiements: Paiement [];
  tableRecapPaiementsAchat: Paiement [];
  tableRecapSoldeInitial: Paiement [];
  tableRecapSoldeInitialAchat: Paiement [];
  Cheques: Paiement[];
  Avoir         : Avoir = {};
  Avoirs        : Avoir[];
  Achat         : Achat = {};
  Achats        : Achat[];
  tableRecapAchats: Achat[];
  tableDetailAvoirs: Avoir[];
  tableDetailAvoirAchats: Avoir[];
  tableDetailAchats: Vente[];
  tableRecapAvoirs: Avoir[];
  tableRecapeAvoirs: Avoir[];
  tableRecapAvoirAchat: Avoir[];
  tableRecapAvoirAchats: Avoir[];
  tableRecapeAchats: Vente[];
  tableCommandeDetail: Vente[];
  tableDetailsVentesRequette: Vente[];
  tableDetailsVentesRequetteBetwin: Vente[];
  totalMontant: number;
  totaldetailBon: number;
  totalQte: number;
  total: number;
  soldeInitial: number;
  montant: number;
  avance: number;
  achatt: number;
  avoir: number;
  avoirAchat: number;
  totalCheque: number;
  reste: number;

  //ACHAT
  tableAchats: Achat[];
  selectedAchat:Achat;
  nomFilter: string;
  tableRecapAvoirsAchat: Achat[];
  tableDetailPaiements: Paiement[];
  soldeInitialAchat: number;
  totalAchat: number;
  avanceAchat: number;
  resteAchat: number;
  totalChequeAchat: number;
  cols: any;
   // Upload Text File
  uploadedFiles: any[] = [];
  fileBuffer:string = "";
  fileLines:string[] = [];
  debug: string = "";
  file: File;
  reader: FileReader;
  constructor(
    private data: DataService, private http: HttpClient,
    private messageService: MessageService,
    private _decimalPipe: DecimalPipe,
    public AuthenticationService: AuthenticationService,
    private router: Router) {}
    transformDecimal(num) {
      return this._decimalPipe.transform(num, "1.2-2");
    }
  ngOnInit() { 
    this.cols = [
      { field: 'Field', header: 'Field' },
      { field: 'Type', header: 'Type' },
      { field: 'Default', header: 'Default' }
  ];   
    if(this.AuthenticationService.currentUserValue.groupe=='Administrateur' || this.AuthenticationService.currentUserValue.groupe=='Administrateurs' ){
      this.able=true;
    }
    this.modePaiement();
    this.donnee();    
    this.stee();    
    this.stockDepot();
    this.vente();
    this.achat();
  }
  myUploader(event) {
    for(let file of event.files) {
      this.uploadedFiles.push(file);
    }
  }

  myUploaderEnd(event) {
    this.readFile(this.uploadedFiles[0]);
  }

  readFile(file: File) {
    console.log(file);
    
    this.reader.readAsText(file);
  }
  stee() {
    this.Donnee.action = "Select";
    this.dataTable = "ste";
    this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.stes = x));
  }
  getStructureDataBase(dataBase,dataa){
    // this.Donnee.action = "Select";
    if (dataBase && dataa) {
      if(dataa==1){
        this.data1 = [];
      }else{
        this.data2 = [];
      } 
      this.dataTable = "databaseDiff";
      this.Donnee.DBase = dataBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((x: DataBase[]) => 
          {
            if(dataa==1){
              this.data1 = x;
              console.log(x);
              console.log(Object(x));
              // var result = [];
  
              // for(var i in x)
              //   result.push([i, x[i]]);
              // this.files=
            //   [
            //     {
            //         key: '0',
            //         label: 'Documents',
            //         data: 'Documents Folder',
            //         icon: 'pi pi-fw pi-inbox',
            //         children: [
            //             {
            //                 key: '0-0',
            //                 label: 'Work',
            //                 data: 'Work Folder',
            //                 icon: 'pi pi-fw pi-cog',
            //                 children: [
            //                     { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
            //                     { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
            //                 ]
            //             },
            //             {
            //                 key: '0-1',
            //                 label: 'Home',
            //                 data: 'Home Folder',
            //                 icon: 'pi pi-fw pi-home',
            //                 children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
            //             }
            //         ]
            //     },
            //     {
            //         key: '1',
            //         label: 'Events',
            //         data: 'Events Folder',
            //         icon: 'pi pi-fw pi-calendar',
            //         children: [
            //             { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
            //             { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
            //             { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
            //         ]
            //     },
            //     {
            //         key: '2',
            //         label: 'Movies',
            //         data: 'Movies Folder',
            //         icon: 'pi pi-fw pi-star-fill',
            //         children: [
            //             {
            //                 key: '2-0',
            //                 icon: 'pi pi-fw pi-star-fill',
            //                 label: 'Al Pacino',
            //                 data: 'Pacino Movies',
            //                 children: [
            //                     { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
            //                     { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
            //                 ]
            //             },
            //             {
            //                 key: '2-1',
            //                 label: 'Robert De Niro',
            //                 icon: 'pi pi-fw pi-star-fill',
            //                 data: 'De Niro Movies',
            //                 children: [
            //                     { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
            //                     { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
            //                 ]
            //             }
            //         ]
            //     }
            // ];
            }else{
              this.data2 = x
            }        
          }
        );     
    }
  }
  donnee() {
    this.Donnee.action = "Select";
    this.dataTable = "database";
    this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: DataBase[]) => (this.Donnees = x));
  }
  stockDepot() {
    // this.Donnee.action = "BackupRecapDepotAllArticle";
    this.Donnee.action = "SelectRecapDepotAllArticle";
    this.dataTable = "stockDepot";
    this.Donnee.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .donnee(this.dataTable,this.Donnee)
      .toPromise()
      .then((x: Data[]) => (this.Datass=x,this.DataTables=x,this.filterStock()));
  }

  filterStock(){
    if(!this.designation){this.designation="";}
    if(!this.depott){this.depott="";}
    let recherche = this.Datass.filter((x) => 
      x.designation.toString().toLocaleLowerCase().includes(this.designation.toLocaleLowerCase()) &&
      x.depot.toString().toLocaleLowerCase().includes(this.depott.toLocaleLowerCase())   &&
      (x.stockReel>0 || x.stockReel<0)
    );
    if (recherche){
      this.DataTables = recherche;
      this.stockReel  = 0;
      this.stockReel  =  this.DataTables.map((t) => t.stockReel * 1).reduce((a, value) => a + value, 0);
    }
  }
  vente() {
    this.blockedDocument=true;
    this.Vente.action = "RecapByClientGlobal";
    this.Vente.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapClient(this.Vente)
      .toPromise()
      .then(
        (x: Vente[]) => (
          this.Ventes = x, 
          this.tableVentes = x, 
          this.paiementRecap())
      );
  }
  paiementRecap() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiements = x,
        this.soldesInitial()));
  }
  soldesInitial() {
    this.Paiement.action = "soldeInitial";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .paiementClient(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapSoldeInitial = x,
        this.avoirRecap()));
  }
  avoirRecap() {
    this.Avoir.action = "SelectRecapByIdClientGlobal";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoir(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirs = x,
        this.avoirAchatRecap()));
  }
  avoirAchatRecap() {
    this.Avoir.action = "SelectRecapByIdFournisseurClient";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Avoir[]) => (
        this.tableRecapAvoirAchats = x,
        this.achatRecap()));
  }
  achatRecap() {
    this.Achat.action = "SelectRecapByIdClient";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Achat)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapAchats = x,
        // console.log(x),
        this.tablePaiement()));
  }
  tablePaiement(){
    if (this.tableRecapPaiements.length!=undefined){
      // console.log(this.tableRecapPaiements);
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiements.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avance = 0;
            this.tableVentes[index].reste = this.tableVentes[index].total;  
          }else{
            // if(updateItem.modePaiement=='Solde'){
            //   this.tableVentes[index].soldeInitial = -updateItem.montant;
            //   this.tableVentes[index].avance = 0;
            //   this.tableVentes[index].reste = this.tableVentes[index].total-updateItem.montant;
            // }else{
              this.tableVentes[index].avance = updateItem.montant;
              this.tableVentes[index].reste = this.tableVentes[index].total-updateItem.montant;
            // }
          }
        });
    } 
    this.tableSoldeInitial();
}
  tableSoldeInitial(){
    if (this.tableRecapSoldeInitial.length!=undefined){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapSoldeInitial.find(item=>item.idClient==row.idClient);
          // if(row.idClient==1) {
          //   console.log(updateItem);
          // }
          
          if (updateItem===undefined){
            this.tableVentes[index].soldeInitial = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].soldeInitial = updateItem.montant;
          this.tableVentes[index].reste += -updateItem.montant; 
          }
        }); 
    }
    this.tableAvoir();
}
  tableAvoir(){
    if (this.tableRecapAvoirs.length!=undefined){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirs.find(item=>item.idClient==row.idClient);
          if (updateItem===undefined){
            this.tableVentes[index].avoir = 0;
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].avoir = updateItem.montant;
          this.tableVentes[index].reste += -updateItem.montant;      
          }
        }); 
    }
    this.tableAvoirAchatt();
}
  tableAvoirAchatt(){
    if (this.tableRecapAvoirAchats){
      // console.log(this.tableRecapAvoirAchats);
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAvoirAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].avoirAchat = 0;
            
            // this.tableVentes[index].reste += -updateItem.montant;  
          }else{
          this.tableVentes[index].avoirAchat = updateItem.montant;
          this.tableVentes[index].reste += updateItem.montant;      
          }
        }); 
    }else{
      this.tableVentes.forEach((row ,index )=>{
        this.tableVentes[index].avoirAchat = 0;
      });
    }
    this.tableAchat();
}
  tableAchat(){
    if (this.tableRecapAchats.length!=undefined){
        this.tableVentes.forEach((row ,index )=>{
          let updateItem = this.tableRecapAchats.find(item=>item.idClient==row.idClient);
          // console.log(updateItem);
          if (updateItem===undefined){
            this.tableVentes[index].achat = 0;
          }else{
          this.tableVentes[index].achat = updateItem.total;
          this.tableVentes[index].reste += -updateItem.total;      
          }
        }); 
    }
     this.filter();
}
// filterCommande(startDate,endDate){
//   let recherche = this.tableDetailsVentesRequette.filter((x) => x.dateCommande >= startDate && x.dateCommande <= endDate+1 );
//   this.tableDetailsVentesRequetteBetwin = recherche;
//   // console.log(recherche);
// }

  filter() {
    if (this.nom==undefined){this.nom='';}
    if (this.nomAr==undefined){this.nomAr='';}
    if (this.codeClient==undefined){this.codeClient='';}
    if(this.Ventes){
      let recherche = this.Ventes.filter((x) => (
        x.nom.toString().toLocaleLowerCase().includes(this.nom.toString().toLocaleLowerCase()) &&
        x.nomAr.toString().toLocaleLowerCase().includes(this.nomAr.toString().toLocaleLowerCase()) &&
        // x.code.toString().includes(this.codeClient)
        x.code.toString().toLocaleLowerCase().includes(this.codeClient.toString().toLocaleLowerCase()) 
        // &&
        // x.reste!=0 
        ));
      this.tableVentes = recherche;
      this.totalCommande();  
    }
  }

  totalCommande() {
    this.soldeInitial = 0;
    this.total = 0;
    this.avance = 0;
    this.avoir = 0;
    this.totalCheque = 0;
    this.reste = 0;
    console.log(this.tableVentes);
    this.soldeInitial = this.tableVentes.map((t) => (t.soldeInitial) * 1).reduce((a, value) => a + value, 0);
    this.total        = this.tableVentes.map((t) => (t.total) * 1).reduce((a, value) => a + value, 0);
    this.avance       = this.tableVentes.map((t) => (t.avance) * 1).reduce((a, value) => a + value, 0);
    this.achatt       = this.tableVentes.map((t) => (t.achat) * 1).reduce((a, value) => a + value, 0);
    this.avoir        = this.tableVentes.map((t) => (t.avoir) * 1).reduce((a, value) => a + value, 0);
    // this.avoirAchat   = this.tableVentes.map((t) => (t.avoirAchat) * 1).reduce((a, value) => a + value, 0);
    this.reste        = this.tableVentes.map((t) => (t.reste) * 1).reduce((a, value) => a + value, 0);
    // this.totalCheque  = this.Cheques.map((t) => (t.montant) * 1).reduce((a, value) => a + value, 0);
    this.blockedDocument=false;
  } 

  //ACHAT
  achat() {
    this.Achat.action = "RecapByFournisseur";
    this.Achat.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Achat)
      .toPromise()
      .then(
        (x: Achat[]) => (this.Achats = x, this.tableAchats = x,this.paiementRecapAchat())
      );
  }
  paiementRecapAchat() {
    this.Paiement.action = "Recap";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapPaiementsAchat = x,
        this.soldesInitialAchat()));
  }
  
  soldesInitialAchat() {
    this.Paiement.action = "soldeInitial";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableRecapSoldeInitialAchat = x,
        this.avoirRecapAchat()));
  }
  avoirRecapAchat() {
    this.Avoir.action = "SelectRecapByIdFournisseur";
    this.Avoir.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .avoirAchat(this.Avoir)
      .toPromise()
      .then((x: Achat[]) => (
        this.tableRecapAvoirsAchat = x,
        this.tablePaiementAchat()));
  }

  tablePaiementAchat(){
    if (this.tableRecapPaiementsAchat.length!=undefined){
      // console.log(this.tableRecapPaiements);
      // console.log(this.tableAchats);
        this.tableAchats.forEach((row ,index )=>{
          let updateItem = this.tableRecapPaiementsAchat.find(item=>item.idFournisseur==row.idFournisseur);
         
          if (updateItem===undefined){
            this.tableAchats[index].avance = 0;
            this.tableAchats[index].reste = this.tableAchats[index].total;  
          }else{
          this.tableAchats[index].avance = updateItem.montant;
          this.tableAchats[index].reste = this.tableAchats[index].total-updateItem.montant;      
          }
          // console.log(this.tableAchats);
        }); 
        this.tableSoldeInitialAchat();
    }
}
tableSoldeInitialAchat(){
  if (this.tableRecapSoldeInitialAchat.length!=undefined){
      this.tableAchats.forEach((row ,index )=>{
        let updateItem = this.tableRecapSoldeInitialAchat.find(item=>item.idFournisseur==row.idFournisseur);
        // console.log(updateItem);
        if (updateItem===undefined){
          this.tableAchats[index].soldeInitial = 0;
          // this.tableVentes[index].reste += -updateItem.montant;  
        }else{
        this.tableAchats[index].soldeInitial = updateItem.montant;
        this.tableAchats[index].reste += -updateItem.montant;      
        }
      }); 
  }
  this.tableAvoirAchat();
}
tableAvoirAchat(){
  if (this.tableRecapAvoirsAchat.length!=undefined){
    // console.log(this.tableRecapAvoirs);
    // console.log(this.tableAchats);
      this.tableAchats.forEach((row ,index )=>{
        let updateItem = this.tableRecapAvoirsAchat.find(item=>item.idFournisseur==row.idFournisseur);
        
        if (updateItem===undefined){
          this.tableAchats[index].avoir = 0; 
          // this.tableVentes[index].reste += -updateItem.montant;
          // console.log(updateItem);
        }else{
          this.tableAchats[index].avoir = updateItem.montant;
          this.tableAchats[index].reste += -updateItem.montant;
        }
      }); 
  }
  // console.log(this.tableAchats);
   this.filterAchat(this.nomFilter,'nom');
}
filterAchat(data, colone) {
  // if (this.nomFilter===undefined){
  //   this.nomFilter='';
  // }
  if (data===undefined){ data=''; }
  let recherche;
  if (colone=="idFournisseur"){
     recherche = this.Achats.filter((x) => x.idFournisseur.toString().includes(data));
  }else{
    recherche = this.Achats.filter(
    (x) =>
      x[colone].toLocaleLowerCase().indexOf(data.toLocaleLowerCase()) > -1 &&
      (x.reste>0 || x.reste<0)
    ); 
  }
  // console.log(recherche);
  this.tableAchats = recherche;
  this.totalCommandeAchat();  
}
totalCommandeAchat() {
  this.soldeInitialAchat = 0;
  this.totalAchat = 0;
  this.avanceAchat = 0;
  this.totalChequeAchat = 0;
  this.resteAchat = 0;
  this.soldeInitialAchat = this.tableAchats.map((t) => (t.soldeInitial) * 1).reduce((a, value) => a + value, 0);
  this.totalAchat = this.tableAchats.map((t) => (t.total) * 1).reduce((a, value) => a + value, 0);
  this.avanceAchat = this.tableAchats.map((t) => (t.avance) * 1).reduce((a, value) => a + value, 0);
  this.avoirAchat = this.tableAchats.map((t) => (t.avoir) * 1).reduce((a, value) => a + value, 0);
  this.resteAchat = this.tableAchats.map((t) => (t.reste) * 1).reduce((a, value) => a + value, 0);
  // this.totalChequeAchat = this.Cheques.map((t) => (t.montant) * 1).reduce((a, value) => a + value, 0);
}
  paiementDetail() {
    this.Paiement.action = "Select";
    this.Paiement.DBase  = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .recapFournisseur(this.Paiement)
      .toPromise()
      .then((x: Paiement[]) => (
        this.tableDetailPaiements = x,
        this.Cheques =x.filter((x) => x.modePaiement != 'Espèce'),
        this.filterAchat(this.nomFilter, 'nom')));
  }
  
  clotureMigration(){
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Donnee.action = "Migration";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'success', summary: 'Sauvegarder', detail: res.message});
            this.blockedDocument=false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  clotureStockPreparation(){
    var requete='INSERT INTO '+this.Paiement.dataBase+'.stockdepot (idArticle,idDepot,dateVariation,variation,entree,sortie,observation) VALUES ';
    let recherche= this.DataTables.filter((x) => x.stockReel != 0);
    recherche.forEach((row ,index )=>{
      requete+="("+row.id+",'"+row.idDepot+"', '"+this.Paiement.dateCommande+"', 1, "+row.stockReel+",'','Stock Initial'),";
    });
    requete+=";";
    this.Donnee.requeteStock=requete.replace(",;", ";");
  }

  cloturePaiementClientPreparation(){    
    var requete='INSERT INTO '+this.Donnee.toNewDataBase+'.situationpaiementclient (idClient,dateReglement,montant,idModePaiement,regle) VALUES ';
    let recherche= this.tableVentes.filter((x) => x.reste != 0);
    recherche.forEach((row ,index )=>{
      requete+="("+row.idClient+", '"+this.Paiement.dateCommande+"', -"+row.reste+","+this.idModePaiement+",'o'),";
    });
    requete+=";";
    this.Donnee.requeteClient=requete.replace(",;", ";");
  }

  cloturePaiementFournisseurPreparation(){
    var requete='INSERT INTO '+this.Donnee.toNewDataBase+'.situationpaiementfournisseur (idfournisseur,dateReglement,montant,idModePaiement,regle) VALUES ';
    let recherche= this.tableAchats.filter((x) => x.reste != 0);
    recherche.forEach((row ,index )=>{
      requete+="("+row.idFournisseur+", '"+this.Paiement.dateCommande+"', -"+row.reste+","+this.idModePaiement+",'o'),";
    });
    requete+=";";
    this.Donnee.requeteFournisseur=requete.replace(",;", ";");
    // console.log(this.tableAchats);
  }

  clotureStock(){
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Donnee.action = "RequetteStock";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'success', summary: 'Sauvegarder', detail: res.message});
            this.blockedDocument=false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  cloturePaiementClient(){
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Donnee.action = "RequeteClient";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'success', summary: 'Sauvegarder', detail: res.message});
            this.blockedDocument=false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }

  cloturePaiementFournisseur(){
    if (confirm("êtes vous sure?")) {
      this.blockedDocument=true;
      this.Donnee.action = "RequeteFournisseur";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'success', summary: 'Sauvegarder', detail: res.message});
            this.blockedDocument=false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  onUpload() {
    // alert(this.ModePaiement.modePaiement);
    this.ModePaiement.chemin = this.myUrl + "/API/API/";
    this.data
      .posteFile(
        this.ModePaiement.id,
        this.ModePaiement.modePaiement,
        this.ModePaiement.chemin,
        this.fileToUpload
      )
      .subscribe((data: { message: string; url: string }) => {
        // alert(data);
        // console.log(data);
        alert(data.message);
        this.modePaiement();
        this.displayDialog = false;
      });
  }
  modePaiement() {
    this.ModePaiement.action = "Select";
    this.ModePaiement.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data
      .modePaiement(this.ModePaiement)
      .toPromise()
      .then((x: ModePaiement[]) => (this.ModePaiements = x, this.filterModePaiements()));
  }
  filterModePaiements(){
    let recherche = this.ModePaiements.filter((x) => x.modePaiement.toString().includes('Solde'));
    this.ModePaiements=recherche;

    // this.Paiement.modePaiement = this.ModePaiements.idModePaiement;
  }
  findModePaiement(idModePaiement) {
    if(idModePaiement){
      let rechercheModePaiement = this.ModePaiements.find((x) => x.id == idModePaiement);
      this.Paiement.modePaiement = rechercheModePaiement.modePaiement;
      this.idModePaiement = rechercheModePaiement.id;
    }
  }
  save() {
    if (this.Donnee.data == null ||this.Donnee.data == '' || !this.Donnee.designation) {
      this.messageService.add({severity:'warn', summary: 'Attention !!!', detail: 'Il manque quelque chose ?'});
    } else {
      if (this.Donnee.id) {
        this.Donnee.action = "Update";
        this.dataTable = "database";
        this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data        
        .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              alert(res.message);
              alert("Nonnnn");
            }
          });
      } else {
        this.Donnee.action = "Insert";
        this.dataTable = "database";
        this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
        this.data
          .donnee(this.dataTable,this.Donnee)
          .toPromise()
          .then((res: { message: string }) => {
            if (res.message == "Opération de modification réuissie") {
              this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
              this.donnee();
            } else {
              this.messageService.add({severity:'warn', summary: 'Sauvegarde', detail: res.message});
              this.displayDialog = false;
            }
          });
      }
    }
  }

  saveSte(){
    this.ste.action = "Update";
    this.dataTable = "ste";
    this.ste.DBase = this.AuthenticationService.currentUserValue.DBase;
    this.data        
    .donnee(this.dataTable,this.ste)
      .toPromise()
      .then((res: { message: string }) => {
        if (res.message == "Opération de modification réuissie") {
          this.messageService.add({severity:'success', summary: 'Sauvegarde', detail: res.message});
          this.displayDialogSte=false;
          this.stee();
        } else {
          alert(res.message);
          alert("Nonnnn");
        }
      });
  }

  delete() {
    if (confirm("êtes vous sure?")) {
      this.Donnee.dateNow = new Date().toISOString().split("T")[0]; 
      this.Donnee.action = "Delete";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de supression réuissie")) {
            this.messageService.add({severity:'warn', summary: 'Suppression', detail: res.message});
            this.displayDialog = false;
            this.donnee();
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  actionLink(lien) {
    this.router.navigate(["/"+lien]);
  }
  close() {
    this.displayDialog = false;
    this.ModePaiement = {};
  }
  newDonnee: boolean;
  AddDataBaseVide(){
    if (confirm("êtes vous sure?")) {
      this.Donnee.action = "dbVide";
      this.dataTable = "database";
      this.Donnee.DBase = this.AuthenticationService.currentUserValue.DBase;
      this.data
        .donnee(this.dataTable,this.Donnee)
        .toPromise()
        .then((res: { message: string }) => {
          if ((res.message = "Opération de modification réuissie")) {
            this.messageService.add({severity:'success', summary: 'Base Vide', detail: res.message});
            this.displayDialogMigration=true;
          } else {
            alert("Nonnnn");
          }
        });
    }
  }
  showDialogToAdd() {
    this.newDonnee = true;
    this.imageUrl = this.myUrl + "/API/API/uploads/default.png";
    this.Donnee = {};
    this.Donnee.actif=false;
    this.displayDialog = true;
  }
  onRowSelect(event) {
    this.displayDialog = true;
    this.newDonnee = false;
    // var leet = this.myUrl + "/API/API/uploads/images/" + event.data.images;
    // this.imageUrl = leet;
    // alert(leet);
    this.Donnee = this.cloneDonnee(event.data);
  }
  cloneDonnee(c: DataBase): DataBase {
    let DataBase = {};
    for (let prop in c) {
      DataBase[prop] = c[prop];
    }
    return DataBase;
  }
  onRowSelectSte(event) {
    this.newDonnee = false;
    this.ste = this.cloneDonneeSte(event.data);
    this.displayDialogSte = true;
  }
  cloneDonneeSte(c: DataBase): DataBase {
    let DataBase = {};
    for (let prop in c) {
      DataBase[prop] = c[prop];
    }
    return DataBase;
  }
}
